import React, { useEffect } from 'react';
import moment from 'moment';
import {
  Modal,
  Grid2 as Grid,
  Typography,
  Box,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { DatePicker, CoreButton, BaseTextField } from '../../../core';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '1px solid',
  borderRadius: '5px',
  p: 2,
};

export default function CustomerHoldForm({ open, handleClose, action, parentHandleSubmit, isLoading, item }) {
  const { control, handleSubmit, watch, formState: { errors }, reset, register } = useForm({
    defaultValues: {
      startDate: action === 'update' && item?.startDate ? moment(item.startDate) : null,
      endDate: action === 'update' && item?.endDate ? moment(item.endDate) : null,
      reason: action === 'update' && item?.reason ? item.reason : null,
      extendSubscription: action === 'update' && item?.extendSubscription === false ? false : true,
    }
  });

  useEffect(() => {
    if (item) {
      reset({
        startDate: action === 'update' && item?.startDate ? moment(item.startDate) : null,
        endDate: action === 'update' && item?.endDate ? moment(item.endDate) : null,
        reason: action === 'update' && item?.reason ? item.reason : null,
        extendSubscription: action === 'update' && item?.extendSubscription === false ? false : true,
      });
    }
  }, [item]);

  const onSubmit = (data) => {
    data = {
      ...data,
      id: item?.id,
      startDate: moment(data.startDate).startOf('day').valueOf(),
      endDate: moment(data.endDate).endOf('day').valueOf(),
    }
    parentHandleSubmit(data);
    handleClose();
    reset();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
    >
      <Box sx={style}>
        <Grid container spacing={1}>
          <Grid container>
            <Typography variant="h6" sx={{ mt: 1, mb: 1, color: 'secondary.contrastText.main' }}>
              Add Hold Period
            </Typography>
          </Grid>
          <Grid
            size={12}
            container
            spacing={3}
            sx={{ mt: 1 }}
            component="form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Grid size={12} container spacing={1}>
              <Grid size={6}>
                <Controller
                  control={control}
                  rules={{ required: 'Invalid Start Date' }}
                  name='startDate'
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      required={true}
                      control={control}
                      onChange={(event) => {
                        onChange(event);
                      }}
                      disabled={action === 'update' && moment(item.startDate).isBefore(moment().startOf('day'))}
                      errors={errors}
                      value={value ? moment(value) : null}
                      label="Start Date"
                      disablePast={true}
                    />
                  )}
                />
              </Grid>
              <Grid size={6}>
                <Controller
                  control={control}
                  rules={{
                    required: 'Invalid End Date',
                    validate: value => {
                      if (moment(value).isBefore(moment(watch('startDate')))) {
                        return 'End Date cannot be before Start Date';
                      }
                    }
                  }}
                  name={'endDate'}
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      name='endDate'
                      required={true}
                      control={control}
                      onChange={(event) => {
                        onChange(event);
                      }}
                      disabled={action === 'update' && moment(item.endDate).isBefore(moment().startOf('day'))}
                      errors={errors}
                      value={value ? moment(value) : null}
                      label="End Date"
                      disablePast={true}
                      minDate={moment(watch('startDate'))}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid size={12} container spacing={1}>
              <BaseTextField
                id='reason'
                name="Reason"
                label="Reason"
                errors={errors}
                required={false}
                validate={register('reason')}
              />
            </Grid>
            <Grid size={12} container spacing={1}>
              <Controller
                control={control}
                name="extendSubscription"
                render={({ field: { onChange, value } }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={value}
                        onChange={(event) => {
                          onChange(event.target.checked);
                        }}
                        sx={{
                          color: 'secondary.contrastText',
                          '&.Mui-checked': {
                            color: 'secondary.contrastText',
                          },
                        }}
                      />
                    }
                    label="Extend Subscription End Date"
                  />
                )}
              />
            </Grid>
            <Grid size={12} justifyContent='flex-end' container spacing={1}>
              <Grid size="auto">
                <CoreButton
                  variant='contained'
                  fullWidth={false}
                  isLoading={isLoading}
                  onClickHandler={handleClose}
                >Close
                </CoreButton>
              </Grid>
              <Grid size="auto">
                <CoreButton
                  variant='contained'
                  fullWidth={false}
                  isLoading={isLoading}
                  disabled={!watch('startDate') || !watch('endDate')}
                  type="submit"
                >{action}
                </CoreButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}