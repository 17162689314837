import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
    Modal,
    Typography,
    Grid2 as Grid,
    Chip,
    Divider,
} from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import { CoreButton, TuiSpinner } from '../../../core';
import { capitalizeFirstLetter, getFormattedDateFromUnixTimestamp, getFormattedPhoneNumber } from '../../../js/lib/utils';
import { leadsApi } from '../../../js/slices/api_slices';

const { useGetLeadQuery } = leadsApi;

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,
    p: 2,
    minHeight: 200,
};

export default function LeadViewModal({ open, handleClose, item }) {
    const navigate = useNavigate();
    const settings = useSelector((store) => store?.settings?.settings);
    let { data, isLoading } = useGetLeadQuery({
        id: item?.id,
    }, { refetchOnMountOrArgChange: true, skip: !(item?.id && open) });

    data = data?.data;

    return (
        <Modal
            open={open}
            onClose={() => {
                handleClose();
            }}
        >
            <Grid
                size={12}
                container
                spacing={1}
                sx={style}>
                {isLoading && <TuiSpinner />}
                {!isLoading && <>
                    <Grid size={12} container>
                        <Grid size={6}>
                            <Typography sx={{ color: 'secondary' }} fontWeight='bold' fontSize={19}>
                                {data?.firstName} {data?.lastName}
                            </Typography>
                        </Grid>
                        <Grid size={6} container justifyContent='flex-end'>
                            <Grid size="auto">
                                <CoreButton
                                    startIcon={<EditOutlinedIcon />}
                                    variant='contained'
                                    onClickHandler={() => {
                                        navigate('/leads/' + data?.id);
                                    }}
                                >
                                    Edit
                                </CoreButton>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid size={12}>
                        <Divider />
                    </Grid>
                    <Grid size={12} container spacing={3}>
                        <Grid size={12} container>
                            <Grid size={6}>
                                <Grid size={12}>
                                    <Typography sx={{ color: 'secondary' }} fontWeight='bold'>Start Date</Typography>
                                </Grid>
                                <Grid size={12} container>
                                    <Grid size={8}>
                                        <Typography sx={{ color: 'secondary' }}>{getFormattedDateFromUnixTimestamp(data?.startDate)}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid size={6}>
                                <Grid size={12}>
                                    <Typography sx={{ color: 'secondary' }} fontWeight='bold'>End Date</Typography>
                                </Grid>
                                <Grid size={12} container>
                                    <Grid size={8}>
                                        <Typography sx={{ color: 'secondary' }}>{getFormattedDateFromUnixTimestamp(data?.endDate)}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid size={12} container>
                            <Grid size={6}>
                                <Grid size={12}>
                                    <Typography sx={{ color: 'secondary' }} fontWeight='bold'>Meal Plan</Typography>
                                </Grid>
                                <Grid size={12} container>
                                    <Grid size={8}>
                                        <Typography sx={{ color: 'secondary' }}>{capitalizeFirstLetter(data?.mealPlan?.name)}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid size={6}>
                                <Grid size={12}>
                                    <Typography sx={{ color: 'secondary' }} fontWeight='bold'>Price</Typography>
                                </Grid>
                                <Grid size={12} container>
                                    <Grid size={8}>
                                        <Typography sx={{ color: 'secondary' }}>$ {data?.mealPlan?.plan?.price}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid size={12} container>
                            <Grid size={6}>
                                <Grid size={12}>
                                    <Typography sx={{ color: 'secondary' }} fontWeight='bold'>Phone Number</Typography>
                                </Grid>
                                <Grid size={12} container>
                                    <Grid size={8}>
                                        <Typography sx={{ color: 'secondary' }}>{getFormattedPhoneNumber(data?.phoneNumber?.number)}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid size={6}>
                                <Grid size={12}>
                                    <Typography sx={{ color: 'secondary' }} fontWeight='bold'>Email</Typography>
                                </Grid>
                                <Grid size={12} container>
                                    <Grid size={8}>
                                        <Typography sx={{ color: 'secondary' }}>{data?.email}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid size={12} container>
                            <Grid size={12} container>
                                <Typography sx={{ color: 'secondary' }} fontWeight='bold'>Address</Typography>
                                <Chip size="small" label={data?.shipping} sx={{ ml: 1 }} />
                            </Grid>
                            <Grid size={12}>
                                <Typography sx={{ color: 'secondary' }}>{data?.unit ?
                                    `${data?.unit} - ${data?.address}` :
                                    data?.address}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
                }
            </Grid>
        </Modal >
    );
}
