import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import moment from 'moment-timezone';
import { Grid, Box, Badge, Tooltip, Menu, MenuItem, ListItemIcon, Typography, Divider, Alert } from '@mui/material';
import RestartAltOutlinedIcon from '@mui/icons-material/RestartAltOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';
import DeleteIconOutlined from '@mui/icons-material/DeleteOutlined';
import EditIconOutlined from '@mui/icons-material/EditOutlined';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import WarningIcon from '@mui/icons-material/Warning';
import { deliveryRoutesApi, employeesApi } from '../../../js/slices/api_slices';
import { Hooks, Table, CoreButton, ConfirmationModal, TuiAutoComplete } from '../../../core/index';
import { getRouteStatusChip } from '../routes_utils';
import { getFormattedDateTimeFromUnixTimestamp } from '../../../js/lib/utils';
import { TIFFIN, USERS } from '../../../js/lib/constants';
import RouteHistoryDrawer from '../components/route_history_drawer';

const headCells = [{
  id: 'name',
  title: 'Name',
}, {
  id: 'status',
  title: 'Status',
}, {
  id: 'startTime',
  title: 'Start Time',
}, {
  id: 'endTime',
  title: 'End Time',
}, {
  id: 'stops',
  title: 'Stops',
}, {
  id: 'distance',
  title: 'Distance',
}, {
  id: 'duration',
  title: 'Duration',
},
{
  id: 'driver',
  title: 'Driver',
}, {
  id: 'cost',
  title: 'Cost',
}, {
  id: 'route',
  title: 'Route',
}, {
  id: 'action',
  title: 'Actions',
}];

const { useUpdateDeliveryRouteMutation, useDeleteDeliveryRouteMutation } = deliveryRoutesApi;
const { useGetEmployeesQuery } = employeesApi;

export default function RouteListComponent({ items, isLoading, refetch, setItems, page, setPage }) {
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState(null);
  const { errorSnackBar, successSnackBar } = Hooks.useSnackBarNotification();
  const [newDriverInputValue, setNewDriverInputValue] = useState('');
  const [newDriver, setNewDriver] = useState(null);
  const debouncedDriverInputValue = Hooks.useDebounce(newDriverInputValue, 500);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const [showResetConfirmationModal, setShowResetConfirmationModal] = useState(false);
  const [showCompleteConfirmationModal, setShowCompleteConfirmationModal] = useState(false);
  const [showCancelConfirmationModal, setShowCancelConfirmationModal] = useState(false);
  const [showChangeDriverConfirmationModal, setShowChangeDriverConfirmationModal] = useState(false);
  const [showHistoryDrawer, setShowHistoryDrawer] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const handleClose = (e) => {
    setAnchorEl(null);
    e.stopPropagation();
  };

  const [updateRoute, {
    data: updateRouteData,
    isSuccess: updateRouteIsSuccess,
    error: updateRouteError,
    isError: updateRouteIsError,
    isLoading: updateRouteIsLoading
  }] = useUpdateDeliveryRouteMutation();

  const [deleteRoute, {
    data: deleteRouteData,
    isSuccess: deleteRouteIsSuccess,
    error: deleteRouteError,
    isError: deleteRouteIsError,
    isLoading: deleteRouteIsLoading
  }] = useDeleteDeliveryRouteMutation();

  let { data: existingDriversData,
    isLoading: existingDriversIsLoading } = useGetEmployeesQuery({
      type: USERS.TYPES.EMPLOYEE.DRIVER,
      status: Object.values(USERS.STATUS).filter((item) => item !== USERS.STATUS.BLOCKED),
      contains: { 'firstName': debouncedDriverInputValue, 'lastName': debouncedDriverInputValue },
    }, { refetchOnMountOrArgChange: true, skip: !showChangeDriverConfirmationModal });

  useEffect(() => {
    if (updateRouteIsSuccess && updateRouteData) {
      successSnackBar({ message: 'Route updated successfully' });
      setSelectedItem(null);
      refetch();
    } else if (updateRouteError && updateRouteIsError) {
      setSelectedItem(null);
      errorSnackBar({ message: updateRouteError?.data?.errorMessage });
    }
  }, [updateRouteIsSuccess, updateRouteError, updateRouteIsError]);

  useEffect(() => {
    if (deleteRouteIsSuccess && deleteRouteData) {
      successSnackBar({ message: 'Route deleted successfully' });
      const newItems = items.filter((item) => item.id !== deleteRouteData?.data?.id);
      if (!newItems.length && Number(page) > 1) {
        setPage(page - 1);
      }
      setItems(newItems);
      setSelectedItem(null);
    } else if (deleteRouteError && deleteRouteIsError) {
      errorSnackBar({ message: deleteRouteError?.data?.errorMessage });
      setSelectedItem(null);
    }
  }, [deleteRouteIsSuccess, deleteRouteData, deleteRouteError, deleteRouteIsError]);

  const menuOptions = (item) => [
    {
      id: 'edit',
      title: 'Edit',
      icon: <EditIconOutlined fontSize="small" />,
      action: () => {
        navigate(selectedItem?.id)
      }
    },
    {
      id: 'changeDriver',
      title: 'Change Driver',
      disabled: selectedItem?.status !== TIFFIN.DELIVERY_ROUTES_STATUS.READY &&
        selectedItem?.status !== TIFFIN.DELIVERY_ROUTES_STATUS.NOT_READY,
      icon: <ChangeCircleOutlinedIcon fontSize="small" />,
      action: () => {
        setShowChangeDriverConfirmationModal(true);
      }
    },
    {
      id: 'history',
      title: 'History',
      icon: <HistoryOutlinedIcon fontSize="small" />,
      action: () => {
        setShowHistoryDrawer(true);
      }
    }, {
      id: 'reset',
      title: 'Reset',
      icon: <RestartAltOutlinedIcon fontSize="small" />,
      disabled: selectedItem?.status !== TIFFIN.DELIVERY_ROUTES_STATUS.IN_PROGRESS,
      action: () => {
        setShowResetConfirmationModal(true);
      }
    }, {
      id: 'complete',
      title: 'Complete',
      disabled: selectedItem?.status !== TIFFIN.DELIVERY_ROUTES_STATUS.IN_PROGRESS,
      icon: <CheckCircleOutlineOutlinedIcon fontSize="small" />,
      action: () => {
        setShowCompleteConfirmationModal(true);
      }
    },
    {
      id: 'cancel',
      title: 'Cancel',
      icon: <CancelOutlinedIcon fontSize="small" />,
      disabled: selectedItem?.status === TIFFIN.DELIVERY_ROUTES_STATUS.CANCELLED,
      action: () => {
        setShowCancelConfirmationModal(true);
      }
    }, {
      id: 'delete',
      title: 'Delete',
      icon: <DeleteIconOutlined fontSize="small" />,
      disabled: false,
      action: () => {
        setShowDeleteConfirmationModal(true);
      }
    }
  ];

  const processedItems = items?.map((item) => {
    const { name, stopCount, driver, status, startTime, events, id, distance, duration, unassignedCount, cost } = item;
    const tooltipMessage = unassignedCount > 0 ? `${unassignedCount} stop${unassignedCount > 1 ? 's are' : ' is'} added to the end of the route due to delivery time constraints.<br />Start the route earlier or adjust the time window.<br />Click to see which stops are affected.` : "";
    const warningIcon = unassignedCount > 0 ? (
      <Tooltip
        title={<span style={{ fontSize: '0.9rem' }} dangerouslySetInnerHTML={{ __html: tooltipMessage }} />}
        placement='top'
        arrow
      >
        <WarningIcon style={{ color: '#FFA500', verticalAlign: 'middle', marginRight: '4px' }} />
      </Tooltip>
    ) : null;
    return {
      name: unassignedCount > 0 ? <>{name} {warningIcon}</> : name,
      status: getRouteStatusChip(status),
      startTime: <>
        <Typography variant='body2'>
          Actual: {events?.start ? getFormattedDateTimeFromUnixTimestamp(events?.start) : '--'}
        </Typography>
        <Typography variant='body2' color='secondary'>
          Expected: {getFormattedDateTimeFromUnixTimestamp(startTime)}
        </Typography>
      </>,
      endTime: events?.finish ? getFormattedDateTimeFromUnixTimestamp(events?.finish) : '--',
      stops: stopCount,
      distance: `${distance != null && distance > 0 ? `${(distance / 1000).toFixed(2)} km` : '--'}`,
      duration: <>
        <Typography variant='body2'>
          Actual: {events?.start && events?.finish ? `${moment.duration(moment(events.finish).diff(moment(events.start))).hours()} hr ${moment.duration(moment(events.finish).diff(moment(events.start))).minutes()} min` : '--'}
        </Typography>
        <Typography variant='body2' color='secondary'>
          Expected: {duration != null && duration > 0 ? `${moment.duration(duration, 'seconds').hours()} hr ${moment.duration(duration, 'seconds').minutes()} min` : '--'}
        </Typography>
      </>,
      driver: `${driver?.firstName} ${driver?.lastName}`,
      cost: (
        <Tooltip title={cost?.value ? `$${cost?.value.toFixed(2)} ${cost?.type}` : ''}>
          <span>
            {cost?.total ? `$${cost?.total.toFixed(2)}` : '--'}
          </span>
        </Tooltip>
      ),
      route: moment(item?.startTime).isAfter(moment().startOf('day')) ? <Badge
        sx={{
          '& .MuiBadge-badge': {
            color: '#FF4444',
            backgroundColor: '#FF4444',
          },
        }}
        badgeContent={events?.recreate ? 1 : 0}
      >
        <Tooltip title={
          item?.subscribers?.length === 0 ? 'No stops added' :
            `Last created: ${getFormattedDateTimeFromUnixTimestamp(events?.recreateDateTime)}`} arrow>
          <span>
            <CoreButton
              fullWidth={false}
              isLoading={item?.id === selectedItem?.id && updateRouteIsLoading}
              variant="outlined"
              disabled={!events?.recreate}
              onClickHandler={(e) => {
                setSelectedItem(item);
                const copiedItem = { ...item };
                delete copiedItem.status;
                copiedItem.actionRouteCreate = true;
                updateRoute(copiedItem);
                e.stopPropagation();
              }}
            >
              OPTIMIZE
            </CoreButton>
          </span>
        </Tooltip>
      </Badge> :
        <Tooltip title={'Recreate the route for today'} arrow>
          <span>
            <CoreButton
              fullWidth={false}
              isLoading={item?.id === selectedItem?.id && updateRouteIsLoading}
              variant="contained"
              onClickHandler={(e) => {
                setSelectedItem(item);
                const copiedItem = { ...item };
                delete copiedItem.status;
                copiedItem.actionRouteCreateAndPushHistory = true;
                updateRoute(copiedItem);
                e.stopPropagation();
              }}
            >
              Recreate
            </CoreButton>
          </span>
        </Tooltip>,
      action: <div>
        <LoadingButton
          loading={selectedItem?.id === item?.id && (updateRouteIsLoading || deleteRouteIsLoading)}
          aria-label={`route-long-more-${id}`}
          id={`route-long-button-${id}`}
          aria-controls={open ? `route-long-menu-${id}` : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={(event) => {
            setSelectedItem(item);
            handleClick(event);
            event.stopPropagation();
          }}
        >
          <MoreVertIcon />
        </LoadingButton>
        {selectedItem?.id === item?.id && <Menu
          id={`route-long-menu-${id}`}
          MenuListProps={{
            'aria-labelledby': `route-long-button-${id}`,
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          {menuOptions(item).map(({ id, title, icon, action, disabled }) => [
            <MenuItem
              disabled={disabled}
              key={id}
              onClick={(e) => {
                action();
                handleClose(e);
              }}>
              <ListItemIcon>
                {icon}
              </ListItemIcon>
              {title}
            </MenuItem>,
            (id === "history" || id === "complete") && <Divider key={`divider-${id}`} />
          ])}
        </Menu>}
      </div>,
      backgroundColor: unassignedCount > 0 ? '#ffe0e0' : '#ffffff',
      id,
    };
  });

  existingDriversData = existingDriversData?.data?.items?.map((item) => ({ ...item, label: `${item?.firstName} ${item?.lastName}` }))
    .filter((item) => item?.id !== selectedItem?.driver?.id);

  const deleteConfirmationModal = <ConfirmationModal
    open={showDeleteConfirmationModal}
    handleClose={() => setShowDeleteConfirmationModal(false)}
    title={<Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant='h6'>Are you sure want to delete this route?</Typography>
      </Grid>
      <Grid item xs={12}>
        <Alert severity='warning'>All the history related to this route will also be deleted.</Alert>
      </Grid>
    </Grid>}
    type="Delete"
    actionHandler={() => {
      setShowDeleteConfirmationModal(false);
      deleteRoute({ id: selectedItem?.id });
    }}
  />;

  const changeDriverConfirmationModal = <ConfirmationModal
    open={showChangeDriverConfirmationModal}
    handleClose={() => {
      setShowChangeDriverConfirmationModal(false)
    }}
    title={<Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant='h6'>{`Select new driver for this route`}</Typography>
      </Grid>
      <Grid item xs={12}>
        <TuiAutoComplete
          id="newDriver"
          name="newDriver"
          loading={existingDriversIsLoading}
          freeSolo
          onChange={(event, item) => {
            setNewDriver(item);
          }}
          inputValue={newDriverInputValue}
          onInputChange={(event, newInputValue) => {
            setNewDriverInputValue(newInputValue);
          }}
          value={newDriver}
          label="New Driver"
          labelKey='label'
          options={existingDriversData}
          disabled={existingDriversIsLoading || existingDriversData?.length === 0}
          helperText={existingDriversData?.length === 0 ?
            <Typography
              variant="h7"
              fontWeight="bold"
              display="inline">
              No Other Driver Exists
              <Box ml={1} display="inline">
                <CoreButton
                  sx={{
                    p: 0,
                    m: 0,
                    color: 'secondary.contrastText',
                    textDecoration: 'underline'
                  }}
                  fullWidth={false}
                  disableRipple
                  onClickHandler={() => navigate('/employees/new')}
                >
                  Add Driver
                </CoreButton>
              </Box>
            </Typography>
            : ''}
        />
      </Grid>
    </Grid>}
    type="Update"
    actionHandler={() => {
      const copiedItem = { ...selectedItem };
      copiedItem.driver = newDriver;
      updateRoute(copiedItem);
      setShowChangeDriverConfirmationModal(false);
      setNewDriver(null);
      setNewDriverInputValue('');
    }}
    actionHandlerDisabled={!newDriver}
  />;

  const resetConfirmationModal = <ConfirmationModal
    open={showResetConfirmationModal}
    handleClose={() => setShowResetConfirmationModal(false)}
    title={<><Typography variant="h6">
      Are you sure want to reset this route?
    </Typography>
      <Typography variant="body2">
        This action will reset the route and all the stops to their initial state.
      </Typography>
    </>}
    type="Reset"
    actionHandler={() => {
      const copiedItem = { ...selectedItem };
      copiedItem.reset = true;
      updateRoute(copiedItem);
      setShowResetConfirmationModal(false);
    }}
  />;

  const cancelConfirmationModal = <ConfirmationModal
    open={showCancelConfirmationModal}
    handleClose={() => setShowCancelConfirmationModal(false)}
    title={<><Typography variant="h6">
      Are you sure want to cancel this route?
    </Typography>
      <Typography variant="body2">
        The route will stop auto-renewing everyday, all the history will be retained.
      </Typography>
    </>}
    type="Cancel"
    actionHandler={() => {
      const copiedItem = { ...selectedItem };
      copiedItem.status = TIFFIN.DELIVERY_ROUTES_STATUS.CANCELLED;
      updateRoute(copiedItem);
      setShowCancelConfirmationModal(false);
    }}
  />;

  const completeConfirmationModal = <ConfirmationModal
    open={showCompleteConfirmationModal}
    handleClose={() => setShowCompleteConfirmationModal(false)}
    title={<Typography variant="h6">
      Are you sure want to complete this route?
    </Typography>}
    type="Complete"
    actionHandler={() => {
      const data = { ...selectedItem, status: TIFFIN.DELIVERY_ROUTES_STATUS.COMPLETED };
      setShowCompleteConfirmationModal(false);
      updateRoute(data);
    }}
  />;

  return (
    <>
      <Grid container>
        <Table
          headers={headCells}
          data={processedItems}
          isLoading={isLoading}
          selectedRow={selectedItem}
          setSelectedRow={(row) => {
            navigate(`${row?.id}/stops`);
          }} />
      </Grid>
      {deleteConfirmationModal}
      {resetConfirmationModal}
      {cancelConfirmationModal}
      {completeConfirmationModal}
      {changeDriverConfirmationModal}
      {showHistoryDrawer && <RouteHistoryDrawer
        open={showHistoryDrawer}
        handleClose={() => setShowHistoryDrawer(false)}
        item={selectedItem}
      />}
    </>
  );
}
