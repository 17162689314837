import React, { useState, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import {
  Grid2 as Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  FormControl,
  FormLabel,
  Typography,
  Alert,
  Drawer,
  InputLabel,
  Checkbox,
  MenuItem,
  Select,
  Menu,
} from '@mui/material';
import TocIconOutlined from '@mui/icons-material/TocOutlined';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { DELIVERY_METHOD, TIFFIN } from '../../../js/lib/constants';
import { BaseTextField, TuiAutoComplete, DatePicker, TimePicker, Hooks, CoreButton, ToggleDays, CircularSpinner } from '../../../core/index';
import { googleMapsApi, tiffinsApi, deliveryRoutesApi } from '../../../js/slices/api_slices';
import { capitalizeFirstLetter } from '../../../js/lib/utils';
import TiffinSubscriberGoodsForm from './customer_tiffin_goods_form';
import { isNotStarted } from '../customer_utils';

const { useGetPlacesQuery } = googleMapsApi;
const { useGetTiffinsQuery, useCreateTiffinItemMutation } = tiffinsApi;
const { useGetRecommendedDeliveryRoutesQuery } = deliveryRoutesApi;

export default function CustomerForm({ register, item = null, errors, viewOnly, control, setValue, watch, getValues, action, reset, isDirty, isLoading }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMouseEnter = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMouseLeave = () => {
    setAnchorEl(null);
  };

  const [showItemsDrawer, setShowItemsDrawer] = useState(false);
  const [showDeliveryWindow, setShowDeliveryWindow] = useState(false);
  // Address states
  const [addressInputValue, setAddressInputValue] = useState('');
  const debouncedAddressInput = Hooks.useDebounce(addressInputValue, 500);

  // Tiffin selection states
  const [tiffinInputValue, setTiffinInputValue] = useState('');
  const debouncedTiffinInput = Hooks.useDebounce(tiffinInputValue, 500);

  const settings = useSelector((store) => store?.settings?.settings);
  const navigate = useNavigate();

  const [addNewTiffinItem] = useCreateTiffinItemMutation();

  const { data, isLoading: getTiffinLoading } = useGetTiffinsQuery({
    page: 1,
    pageSize: 20,
    contains: { name: debouncedTiffinInput },
  }, { refetchOnMountOrArgChange: true });

  const { data: addressData,
    isLoading: addressLoading } = useGetPlacesQuery({
      input: debouncedAddressInput,
    }, { refetchOnMountOrArgChange: true, skip: addressInputValue ? false : true });

  let { data: routesData,
    isLoading: routesDataLoading } = useGetRecommendedDeliveryRoutesQuery({
      addressId: watch('customer.address')?.place_id || watch('customer.address')?.id,
    }, { refetchOnMountOrArgChange: true, skip: !(watch('customer.address')?.place_id || watch('customer.address')?.id) });

  routesData = routesData?.data?.items?.map((item) => {
    return {
      ...item,
      label: `${item?.name} - ${item?.driver?.firstName} (${item?.subscriberCount})`,
    };
  });

  useEffect(() => {
    const planType = watch('tiffin.plan.type');
    const planCount = watch('tiffin.plan.count');

    if (watch('startDate') && (!item?.id || isNotStarted(item))) {
      if (planType === TIFFIN.PLANS.SINGLE) {
        // TODO need to add delivery days check
        setValue('endDate', moment(watch('startDate')));
      } else if (planType === TIFFIN.PLANS.WEEKLY) {
        setValue('endDate', moment(watch('startDate')).add(planCount, 'week').subtract(1, 'day'));
      } else if (planType === TIFFIN.PLANS.BIWEEKLY) {
        setValue('endDate', moment(watch('startDate')).add(planCount * 2, 'week').subtract(1, 'day'));
      } else if (planType === TIFFIN.PLANS.MONTHLY && settings?.daysInMonth) {
        setValue('endDate', moment(watch('startDate')).add(settings?.daysInMonth * planCount, 'days').subtract(1, 'day'));
      } else if (planType === TIFFIN.PLANS.MONTHLY) {
        setValue('endDate', moment(watch('startDate')).add(planCount, 'month').subtract(1, 'day'));
      } else if (planType === TIFFIN.PLANS.CUSTOM) {
        setValue('endDate', watch('endDate'));
      }
    }
  }, [watch('tiffin'), watch('tiffin.plan.type'), watch('tiffin.plan.count'), watch('startDate'), watch('tiffin.plan.price')]);

  useEffect(() => {
    let startDate = watch('startDate');
    let endDate = watch('endDate');
    const deliveryDays = watch('deliveryDays');
    const planCount = watch('tiffin.plan.count');

    if (startDate && endDate && deliveryDays && planCount) {
      let totalDeliveries = 0;
      startDate = moment(startDate).startOf('day');
      endDate = moment(endDate).endOf('day');

      while (startDate.isSameOrBefore(endDate, 'day')) {
        const dayOfWeek = startDate.day();
        if (deliveryDays[dayOfWeek] > 0) {
          totalDeliveries += deliveryDays[dayOfWeek];
        }
        startDate.add(1, 'day');
      }

      setValue('tiffin.plan.totalDeliveries', totalDeliveries);
    }
  }, [watch('startDate'), watch('endDate'), watch('tiffin.plan.count'), watch('deliveryDays')]);

  useEffect(() => {
    if (action === 'create' && watch('tiffin.plan.type') !== TIFFIN.PLANS.CUSTOM) {
      let selectedShipping = watch('shipping');
      const planType = watch('tiffin.plan.type');
      const selectedTiffin = { ...getValues('tiffin'), ...item?.tiffin };
      if (selectedShipping === DELIVERY_METHOD.HOME_DELIVERY) {
        selectedShipping = 'delivery';
      } else if (selectedShipping === DELIVERY_METHOD.PICK_UP) {
        selectedShipping = 'pickUp';
      }

      let planPrice = selectedTiffin?.price?.[selectedShipping]?.[planType];

      if (planPrice === null || planPrice === undefined) {
        planPrice = selectedTiffin?.plan?.price;
      }
      if (planPrice || planPrice === 0) {
        setValue('tiffin.plan.price', planPrice * selectedTiffin?.plan?.count);
      }
    }
  }, [action, watch('tiffin'), watch('shipping'), watch('tiffin.plan.count'), watch('tiffin.plan.type')]);

  useEffect(() => {
    const isDeliveryWindowPresent = watch('deliveryTimeWindow.start') || watch('deliveryTimeWindow.end');
    setShowDeliveryWindow(isDeliveryWindowPresent);
  }, [watch('deliveryTimeWindow.start'), watch('deliveryTimeWindow.end')]);

  useEffect(() => {
    const totalDeliveries = watch('tiffin.plan.totalDeliveries');
    const deliveryDays = watch('deliveryDays');
    if (action === 'create' && watch('tiffin.plan.type') === TIFFIN.PLANS.CUSTOM_DELIVERIES) {
      // update end date based on total deliveries and valid delivery days
      const startDate = watch('startDate');
      let endDate = moment(startDate);
      let deliveriesCount = 0;

      while (deliveriesCount < totalDeliveries) {
        const dayOfWeek = endDate.day();
        if (deliveryDays[dayOfWeek] > 0) {
          deliveriesCount += deliveryDays[dayOfWeek];
        }
        if (deliveriesCount < totalDeliveries) {
          endDate.add(1, 'day');
        }
      }

      setValue('endDate', endDate);
    }
  }, [watch('tiffin.plan.totalDeliveries'), watch('deliveryDays')]);

  return (
    <>
      <Grid
        container
        justifyContent='center'
        sx={{
          pb: 12,
          pr: 1,
          pl: 1,
        }}>
        <Grid container size={8} spacing={2}>
          <Grid container size={12} sx={{
            p: 2,
            backgroundColor: 'white',
            borderRadius: '20px',
            border: '1px solid #E0E0E0',
          }}>
            <Grid size={12}>
              <Typography>Personal Details</Typography>
            </Grid>
            <Grid container size={12} sx={{ mt: 1 }} spacing={2}>
              <Grid size={3}>
                <BaseTextField
                  id='firstName'
                  name="firstName"
                  label="First Name"
                  disabled={viewOnly}
                  errors={errors}
                  value={watch('customer.firstName')}
                  validate={register('customer.firstName', {
                    required: 'First name is required',
                    maxLength: {
                      value: 500,
                      message: 'Length must be less than 500',
                    },
                  },
                  )}
                />
              </Grid>
              <Grid size={3}>
                <BaseTextField
                  id='lastName'
                  name="lastName"
                  value={watch('customer.lastName')}
                  label="Last Name (Optional)"
                  required={false}
                  disabled={viewOnly}
                  errors={errors}
                  validate={register('customer.lastName', {
                    maxLength: {
                      value: 500,
                      message: 'Length must be less than 500',
                    },
                  },
                  )}
                />
              </Grid>
              <Grid size={3} container>
                <Grid size={4}>
                  <BaseTextField
                    id='countryCode'
                    autoComplete="off"
                    name="customer.phoneNumber.countryCode"
                    label="Code"
                    disabled={viewOnly}
                    required={watch('customer.phoneNumber.number')}
                    errors={errors}
                    defaultValue='+1'
                    validate={register('customer.phoneNumber.countryCode', {
                      maxLength: {
                        value: 4,
                        message: 'Invalid',
                      },
                      minLength: {
                        value: 2,
                        message: 'Invalid',
                      },
                    },
                    )}
                  />
                </Grid>
                <Grid size={8}>
                  <BaseTextField
                    id='customer.phoneNumber.number'
                    name="customer.phoneNumber.number"
                    label="Phone Number (Optional)"
                    disabled={viewOnly}
                    required={false}
                    errors={errors}
                    validate={register('customer.phoneNumber.number', {
                      maxLength: {
                        value: 10,
                        message: 'Length must be 10',
                      },
                      minLength: {
                        value: 10,
                        message: 'Length must be 10',
                      },
                    },
                    )}
                  />
                </Grid>
              </Grid>
              <Grid size={3}>
                <BaseTextField
                  id='email'
                  name="email"
                  label="Email (Optional)"
                  type="email"
                  disabled={viewOnly}
                  errors={errors}
                  required={false}
                  validate={register('customer.email', {
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: 'Entered value does not match email format',
                    },
                  },
                  )}
                />
              </Grid>
            </Grid>
            <Grid container size={12} sx={{ mt: 1 }} spacing={2}>
              <Grid size={3}>
                <BaseTextField
                  name="customer.unit"
                  id="customer.unit"
                  label='Unit (Optional)'
                  errors={errors}
                  disabled={viewOnly}
                  required={false}
                  validate={register('customer.unit', {
                    maxLength: {
                      value: 500,
                      message: 'Unit must be less than 500',
                    },
                  })}
                />
              </Grid>
              <Grid size={9}>
                <Controller
                  control={control}
                  rules={{ required: watch('shipping') === DELIVERY_METHOD.HOME_DELIVERY ? 'Address is required' : false }}
                  name="customer.address"
                  render={({ field: { onChange, value } }) => {
                    return (
                      <TuiAutoComplete
                        id="customer.address"
                        name="customer.address"
                        loading={addressLoading}
                        freeSolo
                        errors={errors}
                        onChange={(event, item) => {
                          onChange(item);
                        }}
                        onInputChange={(event, newInputValue) => {
                          setAddressInputValue(newInputValue);
                        }}
                        required={watch('shipping') === DELIVERY_METHOD.HOME_DELIVERY}
                        value={(value?.description || value?.description === '') ? value : null}
                        helperText={'Please do not enter unit number in the address field'}
                        label={watch('shipping') === DELIVERY_METHOD.HOME_DELIVERY ? "Address" : "Address (Optional)"}
                        labelKey='description'
                        options={addressData?.data?.items}
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container size={12} sx={{
            p: 2,
            backgroundColor: 'white',
            borderRadius: '20px',
            border: '1px solid #E0E0E0',
          }}>
            <Grid size={12} container>
              <Grid size={6}>
                <Typography>Meal Plan Details</Typography>
              </Grid>
              <Grid container size={6} justifyContent='flex-end'>
                <CoreButton
                  fullWidth={false}
                  startIcon={<TocIconOutlined />}
                  sx={{ height: '100%' }}
                  disabled={!watch('tiffin.id')}
                  onClickHandler={() => {
                    setShowItemsDrawer(true);
                  }}
                  variant="contained"
                >
                  Customize Items
                </CoreButton>
              </Grid>
            </Grid>
            <Grid size={12} container spacing={3} sx={{ mt: 2 }}>
              <Grid size={12} container spacing={2}>
                <Grid size={4}>
                  <Controller
                    control={control}
                    rules={{ required: 'Meal plan must be valid' }}
                    name="tiffin"
                    render={({ field: { onChange, value } }) => {
                      return (
                        <TuiAutoComplete
                          loading={getTiffinLoading}
                          errors={errors}
                          onChange={(event, item) => {
                            if (item) {
                              const selectedTiffin = { ...getValues('tiffin'), ...item };
                              onChange({ ...getValues('tiffin'), ...item });
                              setValue('tiffin.items', selectedTiffin?.items);
                            } else {
                              setValue('tiffin.plan.price', null);
                              setValue('tiffin.items', []);
                              setValue('tiffin.id', null);
                              setValue('tiffin.name', '');
                              setTiffinInputValue('');
                            }
                          }}
                          onInputChange={(event, newValue) => {
                            setTiffinInputValue(newValue);
                          }}
                          value={value}
                          labelKey='name'
                          label="Select Meal Plan"
                          noOptionsText="No Meal Plan Exists"
                          options={data?.data?.items}
                        />
                      );
                    }}
                  />
                </Grid>
                <Grid container size={6}>
                  <Grid size={6}>
                    <Controller
                      control={control}
                      rules={{ required: 'Invalid Start Date' }}
                      name="startDate"
                      render={({ field: { onChange, value } }) => (
                        <DatePicker
                          name="startDate"
                          id="startDate"
                          control={control}
                          onChange={(event) => {
                            onChange(event);
                          }}
                          disabled={action === 'update' && !isNotStarted(item)}
                          errors={errors}
                          value={value ? moment(value) : null}
                          label="Start Date"
                        />
                      )}
                    />
                  </Grid>
                  <Grid size={6}>
                    <Controller
                      control={control}
                      name="endDate"
                      rules={{ required: 'Invalid End Date' }}
                      render={({ field: { onChange, value } }) => (
                        <DatePicker
                          name="endDate"
                          id="endDate"
                          control={control}
                          disabled={watch('tiffin.plan.type') !== TIFFIN.PLANS.CUSTOM_DATE && !isNotStarted(item)}
                          onChange={(event) => {
                            onChange(event);
                          }}
                          value={value ? moment(value) : null}
                          errors={errors}
                          label="End Date"
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid size={2} >
                  <BaseTextField
                    id='tiffin.plan.price'
                    name="tiffin.plan.price"
                    label="Price"
                    errors={errors}
                    startAdornment={'$'}
                    validate={register('tiffin.plan.price', {
                      required: 'Price is required',
                      pattern: {
                        value: /^[0-9]*\.?[0-9]+$/,
                        message: 'Price must be a number',
                      },
                    })}
                  />
                </Grid>
              </Grid>
              <Grid size={12} container spacing={2}>
                {action === "create" && <Grid size={3}>
                  <Controller
                    rules={{ required: 'Invalid Meal Plan Type' }}
                    control={control}
                    name="tiffin.plan.type"
                    render={({ field }) => {
                      return (
                        <FormControl
                          required={true}
                          fullWidth={true}
                        >
                          <InputLabel
                            id="tiffin.plan.type"
                            sx={{
                              color: errors?.['tiffin.plan.type']?.message ? 'secondary.contrastText.100' : 'default',
                            }}>
                            Plan Type
                          </InputLabel>
                          <Select
                            disabled={action === 'update' && !isNotStarted(item)}
                            id="tiffin.plan.type"
                            error={!!errors?.['tiffin.plan.type']?.message}
                            label="Meal Plan"
                            onChange={(event) => {
                              field.onChange(event.target.value);
                              handleMouseLeave();
                            }}
                            value={field?.value ?? TIFFIN.PLANS.MONTHLY}
                          >
                            {Object.keys(TIFFIN.PLANS).map((planKey) => (
                              <MenuItem key={planKey} value={TIFFIN.PLANS[planKey]} sx={{
                                display: TIFFIN.PLANS[planKey] === TIFFIN.PLANS.CUSTOM_DATE || TIFFIN.PLANS[planKey] === TIFFIN.PLANS.CUSTOM_DELIVERIES ? 'none' : 'block',
                              }}>
                                {capitalizeFirstLetter(TIFFIN.PLANS[planKey])}
                              </MenuItem>
                            ))}
                            <MenuItem onMouseEnter={handleMouseEnter}>
                              Custom
                              <ArrowRightIcon fontSize="small" />
                            </MenuItem>
                            <Menu
                              anchorEl={anchorEl}
                              open={Boolean(anchorEl)}
                              onClose={handleMouseLeave}
                              onChange={handleMouseLeave}
                              anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                              }}
                            >
                              <MenuItem value={TIFFIN.PLANS.CUSTOM_DATE} onClick={() => {
                                field.onChange(TIFFIN.PLANS.CUSTOM_DATE);
                                handleMouseLeave();
                              }}>
                                By Date
                              </MenuItem>
                              <MenuItem value={TIFFIN.PLANS.CUSTOM_DELIVERIES} onClick={() => {
                                field.onChange(TIFFIN.PLANS.CUSTOM_DELIVERIES);
                                handleMouseLeave();
                              }}>
                                By Deliveries
                              </MenuItem>
                            </Menu>
                          </Select>
                        </FormControl>
                      );
                    }}
                  />
                </Grid>}
                {action === "create" && watch('tiffin.plan.type') !== TIFFIN.PLANS.CUSTOM_DELIVERIES && watch('tiffin.plan.type') !== TIFFIN.PLANS.CUSTOM_DATE && <Grid size={2}>
                  <BaseTextField
                    id='tiffin.plan.count'
                    name="tiffin.plan.count"
                    label={`Total ${watch('tiffin.plan.type') === TIFFIN.PLANS.WEEKLY ? 'Weeks' : watch('tiffin.plan.type') === TIFFIN.PLANS.BIWEEKLY ? 'Bi Weeks' : 'Months'}`}
                    disabled={(action === 'update' && !isNotStarted(item)) ||
                      !(watch('tiffin.plan.type') === TIFFIN.PLANS.WEEKLY ||
                        watch('tiffin.plan.type') === TIFFIN.PLANS.BIWEEKLY ||
                        watch('tiffin.plan.type') === TIFFIN.PLANS.MONTHLY)}
                    type="number"
                    errors={errors}
                    required={false}
                    validate={register('tiffin.plan.count', {
                      valueAsNumber: true,
                      required: 'Count is required',
                      min: {
                        value: 1,
                        message: 'Must be greater than 0',
                      },
                    },
                    )}
                  />
                </Grid>}
                {action === "create" && <Grid size={2}>
                  <BaseTextField
                    id='tiffin.plan.totalDeliveries'
                    name="tiffin.plan.totalDeliveries"
                    label='Total Deliveries'
                    disabled={action === 'update' || watch('tiffin.plan.type') !== TIFFIN.PLANS.CUSTOM_DELIVERIES}
                    type="number"
                    errors={errors}
                    required={false}
                    validate={register('tiffin.plan.totalDeliveries', {
                      valueAsNumber: true,
                      required: 'Count is required',
                      min: {
                        value: 1,
                        message: 'Must be greater than 0',
                      },
                    },
                    )}
                  />
                </Grid>}
              </Grid>
            </Grid>
            <Grid container size={12} sx={{
              p: '13px',
              border: '1px solid #e0e0e0',
              borderColor: 'grey.400',
              borderRadius: '5px',
            }}>
              <Controller
                rules={{ required: 'Select valid delivery days' }}
                control={control}
                name="deliveryDays"
                render={({ field }) => {
                  return (
                    <FormControl fullWidth error={!!errors?.deliveryDays?.message}>
                      <Grid container>
                        <Grid size={2}>
                          <FormLabel component="legend">Delivery Days</FormLabel>
                        </Grid>
                        <Grid size={10} container justifyContent='flex-end'>
                          <ToggleDays field={field} />
                          <FormHelperText>{errors?.deliveryDays?.message}</FormHelperText>
                        </Grid>
                      </Grid>
                      {(!settings?.deliveryDays || Object.values(settings?.deliveryDays).every(value => value === 0)) &&
                        <Alert variant="outlined" severity="warning" icon={false} sx={{ mt: 1 }}>
                          <Grid container spacing={2} size={12}>
                            <Grid>
                              <Typography variant="h7" fontWeight="bold">Note: Set Up Default Days</Typography>
                            </Grid>
                            <Grid>
                              <CoreButton
                                fullWidth={false}
                                disableRipple
                                onClickHandler={() => navigate('/settings/general')}
                                sx={{
                                  p: 0,
                                  m: 0,
                                  color: 'secondary.contrastText',
                                  textDecoration: 'underline',
                                }}>Set Default Days</CoreButton>
                            </Grid>
                          </Grid>

                        </Alert>
                      }
                    </FormControl>
                  );
                }}
              />
            </Grid>
            <Grid container size={12} sx={{ mt: 2 }}>
              <Grid size={6}>
                <BaseTextField
                  name="comment"
                  id="comment"
                  multiline
                  rows={2}
                  label='Packing Team Instructions'
                  errors={errors}
                  disabled={viewOnly}
                  required={false}
                  validate={register('comment', {
                    maxLength: {
                      value: 500,
                      message: 'Comment must be less than 500',
                    },
                  })}
                />
              </Grid>
              <Grid size={6}>
                <BaseTextField
                  name="adminNotes"
                  id="adminNotes"
                  multiline
                  rows={2}
                  label='Admin Notes'
                  errors={errors}
                  disabled={viewOnly}
                  required={false}
                  validate={register('adminNotes', {
                    maxLength: {
                      value: 500,
                      message: 'Notes must be less than 500',
                    },
                  })}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid size={12} container sx={{
            p: 2,
            backgroundColor: 'white',
            borderRadius: '20px',
            border: '1px solid #E0E0E0',
          }}>
            <Grid size={12}>
              <Typography>Shipping Details</Typography>
            </Grid>
            <Grid size={12} container>
              <Controller
                rules={{ required: 'Invalid Shipping Method' }}
                control={control}
                name="shipping"
                render={({ field }) => {
                  return (
                    <FormControl fullWidth>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Grid size={2}>
                          <FormLabel component="legend">Type</FormLabel>
                        </Grid>
                        <Grid size={10} container justifyContent='flex-end'>
                          <RadioGroup
                            {...field}
                            onChange={(event, value) => {
                              if (value === DELIVERY_METHOD.PICK_UP) {
                                setValue('driver.id', null);
                                setValue('driver.firstName', '');
                                setValue('driver.lastName', '');
                              }
                              field.onChange(value);
                            }}
                            value={field.value || item?.shipping}
                            row
                            aria-labelledby="via-buttons-group-label"
                            name="shipping"
                          >
                            <FormControlLabel
                              value={DELIVERY_METHOD.PICK_UP}
                              control={<Radio sx={{
                                '& .MuiSvgIcon-root': {
                                  fontSize: 28,
                                },
                                '&, &.Mui-checked': {
                                  color: 'secondary.contrastText',
                                },
                              }} />}
                              label="Pick Up"
                              sx={{ marginRight: '30px' }}
                            />
                            <FormControlLabel
                              value={DELIVERY_METHOD.HOME_DELIVERY}
                              control={<Radio
                                sx={{
                                  '& .MuiSvgIcon-root': {
                                    fontSize: 28,
                                  },
                                  '&, &.Mui-checked': {
                                    color: 'secondary.contrastText',
                                  },
                                }}
                              />}
                              label="Home Delivery" />
                          </RadioGroup>
                        </Grid>
                      </div>
                      <Grid size={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <FormHelperText>{errors?.item?.shipping?.message}</FormHelperText>
                      </Grid>
                    </FormControl>
                  );
                }}
              />
              {watch('shipping') === DELIVERY_METHOD.HOME_DELIVERY &&
                <Grid container size={12} spacing={2}>
                  <Grid size={4}>
                    {routesDataLoading && <CircularSpinner />}
                    {!routesDataLoading && <Controller
                      control={control}
                      name="route"
                      render={({ field: { onChange, value } }) => {
                        return (
                          <TuiAutoComplete
                            id="route"
                            disabled={routesDataLoading || !routesData?.length}
                            name="route"
                            errors={errors}
                            required={false}
                            onChange={(event, val) => {
                              if (val) {
                                onChange(val);
                              } else {
                                setValue('driver.id', null);
                                setValue('driver.firstName', '');
                                setValue('driver.lastName', '');
                                onChange(null);
                              }
                            }}
                            value={value && value.id ? { ...value, label: `${value?.name} - ${value?.driver?.firstName}` } : { label: '' }}
                            labelKey='label'
                            label="Select Route and Driver"
                            noOptionsText="No Item Found"
                            helperText={!addressInputValue ? 'Select Address First' :
                              !routesData?.length ?
                                <>
                                  <Typography
                                    variant="h7"
                                    fontWeight="bold"
                                    display="inline">
                                    No Upcoming Route Found
                                  </Typography>
                                  <CoreButton
                                    sx={{
                                      p: 0,
                                      m: 0,
                                      color: 'secondary.contrastText',
                                      textDecoration: 'underline'
                                    }}
                                    fullWidth={false}
                                    disableRipple
                                    onClickHandler={() => navigate('/routes/new')}
                                  >
                                    Add Route
                                  </CoreButton>
                                </>
                                : ''}
                            options={routesData}
                            groupBy={(option) => option?.recommended ? 'Recommended' : 'Other'}
                          />
                        );
                      }}
                    />}
                  </Grid>
                  <Grid size={8}>
                    <BaseTextField
                      name="deliveryInstructions"
                      id="deliveryInstructions"
                      label="Delivery Instructions"
                      required={false}
                      errors={errors}
                      disabled={viewOnly}
                      helperText="e.g Main Gate"
                      validate={
                        register('deliveryInstructions', {
                          maxLength: {
                            value: 500,
                            message: 'Length must be less than 500',
                          },
                        })
                      } />
                  </Grid>
                  <Grid size={12} container>
                    <Grid size={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={showDeliveryWindow}
                            sx={{
                              color: 'secondary.contrastText',
                              '&.Mui-checked': {
                                color: 'secondary.contrastText',
                              },
                            }}
                            onChange={(e) => {
                              const isChecked = e.target.checked;
                              setShowDeliveryWindow(isChecked);
                              if (!isChecked) {
                                setValue('deliveryTimeWindow.start', null, { shouldDirty: true });
                                setValue('deliveryTimeWindow.end', null, { shouldDirty: true });
                              }
                            }}
                          />
                        }
                        label='Add Delivery Time Window'
                      />
                    </Grid>
                    {showDeliveryWindow && (
                      <Grid size={8} container spacing={2}>
                        <Grid size={6}>
                          <Controller
                            rules={{
                              validate: (value) => {
                                if (watch('deliveryTimeWindow.end') && moment(value).isAfter(watch('deliveryTimeWindow.end'))) {
                                  return 'Start time must be before end time';
                                }
                                return true;
                              }
                            }}
                            control={control}
                            name='deliveryTimeWindow.start'
                            render={({ field: { onChange, value } }) => (
                              <TimePicker
                                name='deliveryTimeWindow.start'
                                id='deliveryTimeWindow.start'
                                maxTime={watch('deliveryTimeWindow.end') ? moment(watch('deliveryTimeWindow.end')) : null}
                                required={false}
                                control={control}
                                onChange={onChange}
                                errors={errors}
                                value={value ? moment(value) : null}
                                label='Delivery Window Start'
                              />
                            )}
                          />
                        </Grid>
                        <Grid size={6}>
                          <Controller
                            rules={{
                              validate: (value) => {
                                if (watch('deliveryTimeWindow.start') && moment(value).isBefore(watch('deliveryTimeWindow.start'))) {
                                  return 'End time must be after start time';
                                }
                                return true;
                              }
                            }}
                            control={control}
                            name='deliveryTimeWindow.end'
                            render={({ field: { onChange, value } }) => (
                              <TimePicker
                                name='deliveryTimeWindow.end'
                                id='deliveryTimeWindow.end'
                                required={false}
                                control={control}
                                onChange={onChange}
                                minTime={watch('deliveryTimeWindow.start') ? moment(watch('deliveryTimeWindow.start')) : null}
                                errors={errors}
                                value={value ? moment(value) : null}
                                label='Delivery Window End'
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>}
            </Grid>
          </Grid>
        </Grid>
      </Grid >
      <Drawer
        anchor="right"
        PaperProps={{
          sx: { width: '45vw' },
        }}
        open={showItemsDrawer}
        onClose={() => setShowItemsDrawer(false)}>
        <TiffinSubscriberGoodsForm
          reset={reset}
          action='update'
          control={control}
          item={item}
          isDirty={isDirty}
          isLoading={isLoading} />
      </Drawer>
    </>
  );
}
