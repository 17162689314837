import React from 'react';
import { Grid, Typography } from '@mui/material';

import Copyright from './copyright';
import Logo from '../assets/images/dinespot_black_logo.png';
import BackgroundImage from '../assets/images/login_screen.jpg';

export default function PublicContainer({ component }) {
  return (
    <Grid
      container
      height={'100vh'}
      sx={{
        // backgroundImage: `url(${BackgroundImage})`,
        // backgroundSize: 'cover',
        backgroundPosition: 'center',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        paddingTop: '20px',
      }}
    >
      <Grid item xs={12} container direction="column" justifyContent='center' alignItems='center' sx={{ p: 2 }}>
        <img src={Logo} alt='Dinespot Logo' width="15%" />
        <Typography variant="h5" fontFamily="Gill sans" sx={{ mt: 1, color: 'black' }}>
          Tiffin/Meal Subscription CRM
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ mt: -5 }}>
        {component}
      </Grid>
      <Grid item xs={12} container justifyContent='center' alignItems='center' sx={{ mt: 'auto', mb: 2 }}>
        <Copyright />
      </Grid>
    </Grid>
  );
}