import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import {
  Box,
  Grid,
  Divider,
  Typography,
  FormLabel,
  FormControl,
  FormControlLabel,
  Checkbox,
  Alert,
  Select,
  MenuItem,
  InputLabel
} from '@mui/material';
import { ToggleDays } from '../../../core/index';

export default function GeneralSettings() {
  const { control, formState: { errors, dirtyFields }, watch, getValues } = useFormContext();
  return (
    <Box sx={{ minHeight: '100vh', backgroundColor: 'background.default', pb: 8 }}>
      <Grid container sx={{
        p: 2,
        backgroundColor: 'white',
        borderRadius: '20px',
        border: '1px solid #E0E0E0',
      }}>
        {/* General Settings */}
        <Grid item xs={12} container>
          <Grid item xs={6}>
            <Typography variant="h6">
              General
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} container spacing={1} sx={{ pl: 1, pt: 3 }}>
          <Grid item xs={6}>
            <Grid item xs={12} container>
              <Typography id="days-in-month-change">
                Subscription Cycle
              </Typography>
            </Grid>
            <Grid item xs={12} container>
              <FormLabel id="days-in-month-change-label">Custom monthly subscription cycle</FormLabel>
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <FormControl fullWidth size="small" sx={{ mt: 1 }}>
              <Controller
                rules={{ required: 'Invalid Selection' }}
                control={control}
                name="settings.daysInMonth"
                render={({ field }) => (
                  <FormControl
                    required={true}
                    fullWidth={false}
                  >
                    <InputLabel
                      sx={{
                        color: errors?.['daysInMonth']?.message ? 'secondary.contrastText.100' : 'default',
                      }}>
                      Select Days
                    </InputLabel>
                    <Select
                      id="days-in-month"
                      error={!!errors?.['daysInMonth']?.message}
                      label="Days in Month"
                      onChange={(event) => field.onChange(event.target.value)}
                      value={field?.value ?? 'Full Month'} // Default to 'Full Month' if value is null
                    >
                      {[{
                        label: 'Full Month',
                        value: 'Full Month'
                      }, {
                        label: '28 Days',
                        value: 28
                      }].map(({ label, value }) => <MenuItem key={label} value={value}>{label}</MenuItem>)}
                    </Select>
                  </FormControl>
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid item xs={12} container>
          <Grid item xs={12} container spacing={1} sx={{ pl: 1, pt: 3 }}>
            <Grid item xs={6}>
              <Grid item xs={12} container>
                <Typography id="restaurant-type-change">
                  Default Delivery Days
                </Typography>
              </Grid>
              <Grid item xs={12} container>
                <FormLabel id="service-radio-buttons-group-label">Delivery days with counter can also be set for each customer</FormLabel>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Controller
                rules={{ required: 'Invalid Selection' }}
                control={control}
                name="settings.deliveryDays"
                render={({ field }) => {
                  return (
                    <ToggleDays field={field} existingDays={getValues('settings.deliveryDays')} />
                  );
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} container spacing={1} sx={{ pl: 1, pt: 3 }}>
            <Grid item xs={6}>
              <Grid item xs={12} container>
                <Typography id="restaurant-type-change">
                  Route Recommendation
                </Typography>
              </Grid>
              <Grid item xs={12} container>
                <FormLabel id="service-radio-buttons-group-label">Recommend Route and Driver based on route area</FormLabel>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid item xs={12}>
                <FormControl fullWidth size="small" sx={{ mt: 1 }}>
                  <Controller
                    rules={{ required: 'Invalid Selection' }}
                    control={control}
                    name="settings.services.routeRecommendation.enable"
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            {...field}
                            checked={watch('settings.services.routeRecommendation.enable')}
                            disabled={!watch('settings.services.routeRecommendation.eligible')}
                            sx={{
                              color: 'secondary.contrastText',
                              '&.Mui-checked': {
                                color: 'secondary.contrastText',
                              },
                            }}
                          />
                        }
                        sx={{
                          '.MuiFormControlLabel-label': { ml: 1 },
                        }}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} container spacing={1} sx={{ pl: 1, pt: 3 }}>
            <Grid item xs={6}>
              <Grid item xs={12} container>
                <Typography id="tiffin-expiry-continue-change">
                  Send Expired Tiffins
                </Typography>
              </Grid>
              <Grid item xs={12} container>
                <FormLabel id="tiffin-expiry-continue-change-label">Continue sending expired tiffins until manually cancelled</FormLabel>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container>
                <Grid item xs={1}>
                  <FormControl fullWidth size="small" sx={{ mt: 1 }}>
                    <Controller
                      rules={{ required: 'Invalid Selection' }}
                      control={control}
                      name="settings.services.sendExpiredTiffins.enable"
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              {...field}
                              checked={field.value}
                              sx={{
                                color: 'secondary.contrastText',
                                '&.Mui-checked': {
                                  color: 'secondary.contrastText',
                                },
                              }}
                            />
                          }
                          sx={{
                            '.MuiFormControlLabel-label': { ml: 1 },
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={10}>
                  {dirtyFields?.settings?.services?.sendExpiredTiffins?.enable && <Alert severity="info">
                    OPTIMIZE the routes to reflect changes in today&apos;s packing list, stickers, and routes.
                  </Alert>}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} container spacing={1} sx={{ pl: 1, pt: 3 }}>
            <Grid item xs={6}>
              <Grid item xs={12} container>
                <Typography id="days-for-expiry-reminders-change">
                  Expiry Reminder Frequency
                </Typography>
              </Grid>
              <Grid item xs={12} container>
                <FormLabel id="days-for-expiry-reminders-change-label">Specify the number of days before the expiry date to send reminders</FormLabel>
              </Grid>
            </Grid>
            <Grid item xs={2}>
              <FormControl fullWidth size="small" sx={{ mt: 1 }}>
                <Controller
                  rules={{ required: 'Invalid Selection' }}
                  control={control}
                  name="settings.expiryRemindersFrequency"
                  render={({ field }) => (
                    <FormControl
                      required={true}
                      fullWidth={false}
                    >
                      <InputLabel
                        sx={{
                          color: errors?.['settings.expiryRemindersFrequency']?.message ? 'secondary.contrastText.100' : 'default',
                        }}>
                        Select Frequency
                      </InputLabel>
                      <Select
                        id="days-for-expiry-reminders"
                        error={!!errors?.['settings.expiryRemindersFrequency']?.message}
                        label="Days in Month"
                        onChange={(event) => field.onChange(event.target.value)}
                        value={field?.value ?? 0}
                      >
                        {[{
                          label: 'On Expiry Day',
                          value: 0
                        },
                        {
                          label: '1 Day Before (until expiry)',
                          value: 1
                        },
                        {
                          label: '2 Days Before (until expiry)',
                          value: 2
                        }].map(({ label, value }) => <MenuItem key={label} value={value}>{label}</MenuItem>)}
                      </Select>
                    </FormControl>
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        {/* Alerts */}
        <Grid item xs={12} container sx={{ mt: 3 }}>
          <Grid item xs={6}>
            <Typography variant="h6">
              Alerts
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} container>
          <Grid item xs={12} container spacing={1} sx={{ pl: 1, pt: 3 }}>
            <Grid item xs={6}>
              <Grid item xs={12} container>
                <Typography id="restaurant-type-change">
                  Lead Alerts
                </Typography>
              </Grid>
              <Grid item xs={12} container>
                <FormLabel id="service-radio-buttons-group-label">Receive notifications for new customer order submissions</FormLabel>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth size="small" sx={{ mt: 1 }}>
                <Grid item xs={12}>
                  <Controller
                    rules={{ required: 'Invalid Selection' }}
                    control={control}
                    name="settings.services.leadAlertEmail.enable"
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            {...field}
                            edge="end"
                            checked={field.value}
                            inputProps={{ 'aria-label': 'controlled' }}
                            sx={{
                              color: 'secondary.contrastText',
                              '&.Mui-checked': {
                                color: 'secondary.contrastText',
                              },
                            }}
                            disabled={!watch('settings.services.leadAlertEmail.eligible')}
                          />
                        }
                        label="Email"
                        sx={{
                          '.MuiFormControlLabel-label': { ml: 1 },
                        }}
                      />
                    )}
                  />
                  <Controller
                    rules={{ required: 'Invalid Selection' }}
                    control={control}
                    name="settings.services.leadAlertWhatsApp.enable"
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            {...field}
                            edge="end"
                            checked={field.value}
                            inputProps={{ 'aria-label': 'controlled' }}
                            sx={{
                              color: 'secondary.contrastText',
                              '&.Mui-checked': {
                                color: 'secondary.contrastText',
                              },
                            }}
                            disabled={!watch('settings.services.leadAlertWhatsApp.eligible')}
                          />
                        }
                        label="WhatsApp"
                        sx={{
                          '.MuiFormControlLabel-label': { ml: 1 },
                        }}
                      />
                    )}
                  />
                  <Controller
                    rules={{ required: 'Invalid Selection' }}
                    control={control}
                    name="settings.services.leadAlertSms.enable"
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            {...field}
                            edge="end"
                            checked={field.value}
                            inputProps={{ 'aria-label': 'controlled' }}
                            sx={{
                              color: 'secondary.contrastText',
                              '&.Mui-checked': {
                                color: 'secondary.contrastText',
                              },
                            }}
                            disabled={!watch('settings.services.leadAlertSms.eligible')}
                          />
                        }
                        label="SMS"
                        sx={{
                          '.MuiFormControlLabel-label': { ml: 1 },
                        }}
                      />
                    )}
                  />
                </Grid>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={12} container spacing={1} sx={{ pl: 1, pt: 3 }}>
            <Grid item xs={6}>
              <Grid item xs={12} container>
                <Typography id="restaurant-type-change">
                  Expiry Reminder
                </Typography>
              </Grid>
              <Grid item xs={12} container>
                <FormLabel id="service-radio-buttons-group-label">Expiry reminders are sent daily starting two days before expiry until the tiffin expires</FormLabel>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth size="small" sx={{ mt: 1 }}>
                <Grid item xs={12}>
                  <Controller
                    rules={{ required: 'Invalid Selection' }}
                    control={control}
                    name="settings.services.expiryReminderEmail.enable"
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            {...field}
                            edge="end"
                            checked={field.value}
                            inputProps={{ 'aria-label': 'controlled' }}
                            sx={{
                              color: 'secondary.contrastText',
                              '&.Mui-checked': {
                                color: 'secondary.contrastText',
                              },
                            }}
                            disabled={!watch('settings.services.expiryReminderEmail.eligible')}
                          />
                        }
                        label="Email"
                        sx={{
                          '.MuiFormControlLabel-label': { ml: 1 },
                        }}
                      />
                    )}
                  />
                  <Controller
                    rules={{ required: 'Invalid Selection' }}
                    control={control}
                    name="settings.services.expiryReminderWhatsApp.enable"
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            {...field}
                            edge="end"
                            checked={field.value}
                            inputProps={{ 'aria-label': 'controlled' }}
                            sx={{
                              color: 'secondary.contrastText',
                              '&.Mui-checked': {
                                color: 'secondary.contrastText',
                              },
                            }}
                            disabled={!watch('settings.services.expiryReminderWhatsApp.eligible')}
                          />
                        }
                        label="WhatsApp"
                        sx={{
                          '.MuiFormControlLabel-label': { ml: 1 },
                        }}
                      />
                    )}
                  />
                  <Controller
                    rules={{ required: 'Invalid Selection' }}
                    control={control}
                    name="settings.services.expiryReminderSms.enable"
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            {...field}
                            edge="end"
                            checked={field.value}
                            inputProps={{ 'aria-label': 'controlled' }}
                            sx={{
                              color: 'secondary.contrastText',
                              '&.Mui-checked': {
                                color: 'secondary.contrastText',
                              },
                            }}
                            disabled={!watch('settings.services.expiryReminderSms.eligible')}
                          />
                        }
                        label="SMS"
                        sx={{
                          '.MuiFormControlLabel-label': { ml: 1 },
                        }}
                      />
                    )}
                  />
                </Grid>
              </FormControl>
            </Grid>
          </Grid>
          {/* {(watch('settings.services.expiryReminderEmail.enable') || watch('settings.services.expiryReminderWhatsApp.enable') || watch('settings.services.expiryReminderSms.enable')) && <Grid item xs={12} container justifyContent='flex-end' spacing={1} sx={{ pl: 1, pt: 3 }}>
        <Grid item xs={6}>
          <FormControl fullWidth size="small" sx={{ mt: 1 }}>
            <Grid item xs={12}>
              <BaseTextField
                disabled
                multiline
                value={watch('settings.services.expiryReminderSms.message')}
                rows={3}
                name="settings.services.expiryReminderSms.message"
                id="settings.services.expiryReminderSms.message"
                label='Expiry Reminder Message'
                errors={errors}
                required={false}
              />
            </Grid>
          </FormControl>
        </Grid>
      </Grid>} */}
          <Grid item xs={12} container spacing={1} sx={{ pl: 1, pt: 3 }}>
            <Grid item xs={6}>
              <Grid item xs={12} container>
                <Typography id="restaurant-type-change">
                  Pre-Delivery SMS
                </Typography>
              </Grid>
              <Grid item xs={12} container>
                <FormLabel id="service-radio-buttons-group-label">Customers will receive an estimated delivery time notification when the driver begins the route</FormLabel>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth size="small" sx={{ mt: 1 }}>
                <Grid item xs={12}>
                  <Controller
                    rules={{ required: 'Invalid Selection' }}
                    control={control}
                    name="settings.services.preDeliveryEmail.enable"
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            {...field}
                            edge="end"
                            checked={field.value}
                            sx={{
                              color: 'secondary.contrastText',
                              '&.Mui-checked': {
                                color: 'secondary.contrastText',
                              },
                            }}
                            disabled={!watch('settings.services.preDeliveryEmail.eligible')}
                          />
                        }
                        label="Email"
                        sx={{
                          '.MuiFormControlLabel-label': { ml: 1 },
                        }}
                      />
                    )}
                  />
                  <Controller
                    rules={{ required: 'Invalid Selection' }}
                    control={control}
                    name="settings.services.preDeliveryWhatsApp.enable"
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            {...field}
                            edge="end"
                            checked={field.value}
                            sx={{
                              color: 'secondary.contrastText',
                              '&.Mui-checked': {
                                color: 'secondary.contrastText',
                              },
                            }}
                            disabled={!watch('settings.services.preDeliveryWhatsApp.eligible')}
                          />
                        }
                        label="WhatsApp"
                        sx={{
                          '.MuiFormControlLabel-label': { ml: 1 },
                        }}
                      />
                    )}
                  />
                  <Controller
                    rules={{ required: 'Invalid Selection' }}
                    control={control}
                    name="settings.services.preDeliverySms.enable"
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            {...field}
                            checked={field.value}
                            edge="end"
                            sx={{
                              color: 'secondary.contrastText',
                              '&.Mui-checked': {
                                color: 'secondary.contrastText',
                              },
                            }}
                            disabled={!watch('settings.services.preDeliverySms.eligible')}
                          />
                        }
                        label="SMS"
                        sx={{
                          '.MuiFormControlLabel-label': { ml: 1 },
                        }}
                      />
                    )}
                  />
                </Grid>
              </FormControl>
            </Grid>
          </Grid>
          {/* {(watch('settings.services.preDeliveryEmail.enable') || watch('settings.services.preDeliveryWhatsApp.enable') || watch('settings.services.preDeliverySms.enable')) && <Grid item xs={12} container justifyContent='flex-end' spacing={1} sx={{ pl: 1, pt: 3 }}>
        <Grid item xs={6}>
          <FormControl fullWidth size="small" sx={{ mt: 1 }}>
            <Grid item xs={12}>
              <BaseTextField
                multiline
                disabled
                value={watch('settings.services.preDeliverySms.message')}
                rows={3}
                name="settings.services.preDeliverySms.message"
                id="settings.services.preDeliverySms.message"
                label='Pre-Delivery Message'
                errors={errors}
                required={false}
              />
            </Grid>
          </FormControl>
        </Grid>
      </Grid>} */}
          <Grid item xs={12} container spacing={1} sx={{ pl: 1, pt: 3 }}>
            <Grid item xs={6}>
              <Grid item xs={12} container>
                <Typography id="restaurant-type-change">
                  Post-Delivery SMS
                </Typography>
              </Grid>
              <Grid item xs={12} container>
                <FormLabel id="service-radio-buttons-group-label">Sent upon tiffin delivery, includes delivery time, image, and any driver notes</FormLabel>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth size="small" sx={{ mt: 1 }}>
                <Grid item xs={12}>
                  <Controller
                    rules={{ required: 'Invalid Selection' }}
                    control={control}
                    name="settings.services.postDeliveryEmail.enable"
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            {...field}
                            checked={field.value}
                            edge="end"
                            sx={{
                              color: 'secondary.contrastText',
                              '&.Mui-checked': {
                                color: 'secondary.contrastText',
                              },
                            }}
                            disabled={!watch('settings.services.postDeliveryEmail.eligible')}
                          />
                        }
                        label="Email"
                        sx={{
                          '.MuiFormControlLabel-label': { ml: 1 },
                        }}
                      />
                    )}
                  />
                  <Controller
                    rules={{ required: 'Invalid Selection' }}
                    control={control}
                    name="settings.services.postDeliveryWhatsApp.enable"
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            {...field}
                            checked={field.value}
                            edge="end"
                            sx={{
                              color: 'secondary.contrastText',
                              '&.Mui-checked': {
                                color: 'secondary.contrastText',
                              },
                            }}
                            disabled={!watch('settings.services.postDeliveryWhatsApp.eligible')}
                          />
                        }
                        label="WhatsApp"
                        sx={{
                          '.MuiFormControlLabel-label': { ml: 1 },
                        }}
                      />
                    )}
                  />
                  <Controller
                    rules={{ required: 'Invalid Selection' }}
                    control={control}
                    name="settings.services.postDeliverySms.enable"
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            {...field}
                            checked={field.value}
                            edge="end"
                            sx={{
                              color: 'secondary.contrastText',
                              '&.Mui-checked': {
                                color: 'secondary.contrastText',
                              },
                            }}
                            disabled={!watch('settings.services.postDeliverySms.eligible')}
                          />
                        }
                        label="SMS"
                        sx={{
                          '.MuiFormControlLabel-label': { ml: 1 },
                        }}
                      />
                    )}
                  />
                </Grid>
              </FormControl>
            </Grid>
          </Grid>
          {/* {(watch('settings.services.postDeliveryEmail.enable') || watch('settings.services.postDeliveryWhatsApp.enable') || watch('settings.services.postDeliverySms.enable')) && <Grid item xs={12} container justifyContent='flex-end' spacing={1} sx={{ pl: 1, pt: 3 }}>
        <Grid item xs={6}>
          <FormControl fullWidth size="small" sx={{ mt: 1 }}>
            <Grid item xs={12}>
              <BaseTextField
                multiline
                disabled
                value={watch('settings.services.postDeliverySms.message')}
                rows={3}
                name="settings.services.postDeliverySms.message"
                id="settings.services.postDeliverySms.message"
                label='Post-Delivery Message'
                errors={errors}
                required={false}
              />
            </Grid>
          </FormControl>
        </Grid>
      </Grid>} */}
        </Grid>
        {/* Mobile App Settings */}
        <Grid item xs={12} container sx={{ mt: 3 }}>
          <Grid item xs={6}>
            <Typography variant="h6">
              Mobile App
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} container spacing={1} sx={{ pl: 1, pt: 3 }}>
          <Grid item xs={6}>
            <Grid item xs={12} container>
              <Typography id="restaurant-type-change">
                Image Upload
              </Typography>
            </Grid>
            <Grid item xs={12} container>
              <FormLabel id="service-radio-buttons-group-label">Enable driver to upload image on successful delivery</FormLabel>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid item xs={12}>
              <FormControl fullWidth size="small" sx={{ mt: 1 }}>
                <Controller
                  rules={{ required: 'Invalid Selection' }}
                  control={control}
                  name="settings.services.imageUpload.enable"
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          checked={watch('settings.services.imageUpload.enable')}
                          disabled={!watch('settings.services.imageUpload.eligible')}
                          sx={{
                            color: 'secondary.contrastText',
                            '&.Mui-checked': {
                              color: 'secondary.contrastText',
                            },
                          }}
                        />
                      }
                      sx={{
                        '.MuiFormControlLabel-label': { ml: 1 },
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        {watch('settings.services.imageUpload.enable') &&
          <Grid item xs={12} container spacing={1} sx={{ pl: 1, pt: 3 }}>
            <Grid item xs={6}>
              <Grid item xs={12} container sx={{ ml: 5 }}>
                <Typography>
                  Make image upload mandatory for drivers
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid item xs={12}>
                <FormControl fullWidth size="small">
                  <Controller
                    rules={{ required: 'Invalid Selection' }}
                    control={control}
                    name="settings.services.imageUpload.isRequired"
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            {...field}
                            checked={field.value}
                            sx={{
                              color: 'secondary.contrastText',
                              '&.Mui-checked': {
                                color: 'secondary.contrastText',
                              },
                            }}
                          />
                        }
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        }
        <Grid item xs={12} container spacing={1} sx={{ pl: 1, pt: 3 }}>
          <Grid item xs={6}>
            <Grid item xs={12} container>
              <Typography id="restaurant-type-change">
                Show Route Distance
              </Typography>
            </Grid>
            <Grid item xs={12} container>
              <FormLabel id="service-radio-buttons-group-label">Enable driver to see the total route distance</FormLabel>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid item xs={12}>
              <FormControl fullWidth size="small" sx={{ mt: 1 }}>
                <Controller
                  rules={{ required: 'Invalid Selection' }}
                  control={control}
                  name="settings.mobileAppSettings.showRouteDistance"
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          checked={watch('settings.mobileAppSettings.showRouteDistance')}
                          sx={{
                            color: 'secondary.contrastText',
                            '&.Mui-checked': {
                              color: 'secondary.contrastText',
                            },
                          }}
                        />
                      }
                      sx={{
                        '.MuiFormControlLabel-label': { ml: 1 },
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} container spacing={1} sx={{ pl: 1, pt: 3 }}>
          <Grid item xs={6}>
            <Grid item xs={12} container>
              <Typography id="restaurant-type-change">
                Show Route Duration
              </Typography>
            </Grid>
            <Grid item xs={12} container>
              <FormLabel id="service-radio-buttons-group-label">Enable driver to see the total route duration</FormLabel>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid item xs={12}>
              <FormControl fullWidth size="small" sx={{ mt: 1 }}>
                <Controller
                  rules={{ required: 'Invalid Selection' }}
                  control={control}
                  name="settings.mobileAppSettings.showRouteDuration"
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          checked={watch('settings.mobileAppSettings.showRouteDuration')}
                          sx={{
                            color: 'secondary.contrastText',
                            '&.Mui-checked': {
                              color: 'secondary.contrastText',
                            },
                          }}
                        />
                      }
                      sx={{
                        '.MuiFormControlLabel-label': { ml: 1 },
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid >
    </Box >
  );
}
