import React, { useState } from 'react';
import { Divider, Grid2 as Grid, Typography, FormControlLabel, Checkbox } from '@mui/material';
import { LineChart } from '@mui/x-charts/LineChart';

import { TuiAppBar, TuiSpinner } from '../../../core';
import { tiffinsApi } from '../../../js/slices/api_slices';
import { TIFFIN } from '../../../js/lib/constants';
import moment from 'moment';
const { useGetTiffinSubscriberStatsQuery } = tiffinsApi;

export default function TiffinReportsView() {
  const [selectedLabels, setSelectedLabels] = useState([
    TIFFIN.STATUS.ACTIVE,
    TIFFIN.STATUS.EXPIRING_SOON,
    TIFFIN.STATUS.EXPIRED,
    TIFFIN.STATUS.HOLD,
    TIFFIN.STATUS.CANCEL,
    TIFFIN.STATUS.NOT_STARTED,
  ]);

  const {
    data,
    isLoading,
  } = useGetTiffinSubscriberStatsQuery({
    dateFrom: moment().subtract(7, 'days').startOf('day').valueOf(),
    dateTo: moment().add(2, 'days').endOf('day').valueOf(),
  }, { refetchOnMountOrArgChange: true });

  const getChartData = (countKey) => {
    const result = [];
    data?.data?.items?.forEach((item) => {
      result.push(item[countKey]);
    });
    return result;
  };

  const xLabels = data?.data?.items?.map((item) => item?.date);

  const handleLabelChange = (label) => {
    setSelectedLabels((prev) =>
      prev.includes(label)
        ? prev.filter((l) => l !== label)
        : [...prev, label]
    );
  };

  const series = [
    { data: getChartData('activeCount'), label: TIFFIN.STATUS.ACTIVE, color: '#238443' },
    { data: getChartData('expiringSoonCount'), label: TIFFIN.STATUS.EXPIRING_SOON, color: '#EE8D22' },
    { data: getChartData('expiredCount'), label: TIFFIN.STATUS.EXPIRED, color: '#E31A1C' },
    { data: getChartData('holdCount'), label: TIFFIN.STATUS.HOLD, color: '#008B8B' },
    { data: getChartData('cancelledCount'), label: TIFFIN.STATUS.CANCEL, color: '#818181' },
    { data: getChartData('notStartedCount'), label: TIFFIN.STATUS.NOT_STARTED, color: '#2196f3' },
  ].filter((s) => selectedLabels.includes(s.label));

  if (isLoading) return <TuiSpinner />;
  return (
    <Grid container>
      <Grid size={12}>
        <TuiAppBar title="Reports" />
      </Grid>
      <Grid size={12} container justifyContent='flex-start'>
        {Object.values(TIFFIN.STATUS).map((label) => (
          label !== TIFFIN.STATUS.IN_PROGRESS && <FormControlLabel
            key={label}
            control={
              <Checkbox
                sx={{
                  color: 'secondary.contrastText',
                  '&.Mui-checked': {
                    color: 'secondary.contrastText',
                  },
                }}
                checked={selectedLabels.includes(label)}
                onChange={() => handleLabelChange(label)}
              />
            }
            label={label}
          />
        ))}
      </Grid>
      <Grid size={12} container justifyContent='flex-start'>
        <LineChart
          width={600}
          height={400}
          series={series}
          xAxis={[{ scaleType: 'point', data: xLabels }]}
          yAxis={[{ scaleType: 'linear' }]}
        />
      </Grid>
    </Grid>
  );
}
