import React from 'react';
import {
  Grid2 as Grid,
  TextField,
} from '@mui/material';

import { DAYS } from '../js/lib/constants';
import BaseTextField from './base_text_field';

const ToggleDays = ({ field, existingDays, disabled = false, customColor, boxMaxWidth = 80, decimalAllowed = false }) => {
  const handleChange = (day, value) => {
    const floatValue = parseFloat(value);
    const updatedDays = { ...field.value, [day]: decimalAllowed ? floatValue : Math.floor(floatValue) };
    field.onChange(updatedDays);
  };

  return (
    <Grid container spacing={1}>
      {DAYS.map((day, index) => (
        <Grid size={2} key={index} sx={{ maxWidth: boxMaxWidth }}>
          <BaseTextField
            label={day.shortName}
            type="number"
            value={existingDays?.[index] ?? field.value?.[index] ?? ''}
            onChange={(e) => handleChange(index, e.target.value)}
            disabled={disabled}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              min: 0.0,
              step: decimalAllowed ? 0.5 : 1
            }}
            sx={{
              '& .MuiInputBase-input': {
                color: disabled ? (customColor || 'secondary.main') : 'black',
              },
              '& .MuiInputLabel-root': {
                color: disabled ? (customColor || 'secondary.main') : 'black',
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: customColor || 'secondary.main',
                },
                '&:hover fieldset': {
                  borderColor: disabled ? 'black' : 'black',
                },
                '&.Mui-focused fieldset': {
                  borderColor: customColor || 'secondary.main',
                },
              },
            }}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default ToggleDays;