import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useSelector } from 'react-redux';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Modal,
  Grid2 as Grid,
  Typography,
  Box,
  Checkbox,
  Menu
} from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { useForm, Controller } from 'react-hook-form';
import { DatePicker, CoreButton, Hooks, BaseTextField } from '../../../core';
import { tiffinPaymentApi } from '../../../js/slices/api_slices';
import { TIFFIN } from '../../../js/lib/constants';
import { isExpired } from '../customer_utils';
import { capitalizeFirstLetter } from '../../../js/lib/utils';

const { useSnackBarNotification } = Hooks;
const { useCreateTiffinPaymentMutation, useUpdatePaymentMutation } = tiffinPaymentApi;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 650,
  bgcolor: 'background.paper',
  border: '1px solid',
  borderRadius: '5px',
  p: 2,
};

export default function CustomerPaymentForm({ open, handleClose, viewOnly, item, fetchSubscriber, action, rows, setRows, customer }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMouseEnter = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMouseLeave = () => {
    setAnchorEl(null);
  };

  const { errorSnackBar, successSnackBar } = useSnackBarNotification();
  const settings = useSelector((store) => store?.settings?.settings);

  const { handleSubmit, formState: { errors }, control, reset, register, watch, setValue } = useForm({
    defaultValues: {
      startDate: moment(item?.endDate).startOf('day').add(1, 'day').valueOf(),
      endDate: null,
      referenceId: null,
      amount: item?.tiffin?.plan?.price,
      status: TIFFIN.PAYMENTS.PAID,
      expectedPaymentDate: moment().valueOf(),
      planCount: customer?.tiffin?.plan?.count || 1,
      planType: customer?.tiffin?.plan?.type || TIFFIN.PLANS.MONTHLY,
      totalDeliveries: 0,
    },
    mode: 'onChange',
  });

  useEffect(() => {
    if (customer) {
      reset({
        startDate: moment(customer?.endDate).startOf('day').add(1, 'day').valueOf(),
        endDate: null,
        referenceId: null,
        amount: item?.tiffin?.plan?.price,
        status: TIFFIN.PAYMENTS.PAID,
        expectedPaymentDate: moment().valueOf(),
        planCount: customer?.tiffin?.plan?.count || 1,
        planType: customer?.tiffin?.plan?.type || TIFFIN.PLANS.MONTHLY,
      });
    }
  }, [customer]);

  useEffect(() => {
    if (watch('status') === TIFFIN.PAYMENTS.PAID) {
      setValue('expectedPaymentDate', moment().valueOf());
    } else {
      setValue('expectedPaymentDate', null);
    }
  }, [watch('status')]);

  useEffect(() => {
    const planType = watch('planType');
    const planCount = watch('planCount');

    if (watch('startDate')) {
      if (planType === TIFFIN.PLANS.SINGLE) {
        // TODO need to add delivery days check
        setValue('endDate', moment(watch('startDate')));
      } else if (planType === TIFFIN.PLANS.WEEKLY) {
        setValue('endDate', moment(watch('startDate')).add(planCount, 'week').subtract(1, 'day'));
      } else if (planType === TIFFIN.PLANS.BIWEEKLY) {
        setValue('endDate', moment(watch('startDate')).add(planCount * 2, 'week').subtract(1, 'day'));
      } else if (planType === TIFFIN.PLANS.MONTHLY && settings?.daysInMonth) {
        setValue('endDate', moment(watch('startDate')).add(settings?.daysInMonth * planCount, 'days').subtract(1, 'day'));
      } else if (planType === TIFFIN.PLANS.MONTHLY) {
        setValue('endDate', moment(watch('startDate')).add(planCount, 'month').subtract(1, 'day'));
      } else if (planType === TIFFIN.PLANS.CUSTOM) {
        setValue('endDate', watch('endDate'));
      }
    }
  }, [watch('planType'), watch('planCount'), watch('startDate')]);

  useEffect(() => {
    const totalDeliveries = watch('totalDeliveries');
    const deliveryDays = customer?.deliveryDays;
    if (watch('planType') === TIFFIN.PLANS.CUSTOM_DELIVERIES) {
      // update end date based on total deliveries and valid delivery days
      const startDate = watch('startDate');
      let endDate = moment(startDate);
      let deliveriesCount = 0;

      while (deliveriesCount < totalDeliveries) {
        const dayOfWeek = endDate.day();
        if (deliveryDays[dayOfWeek] > 0) {
          deliveriesCount += deliveryDays[dayOfWeek];
        }
        if (deliveriesCount < totalDeliveries) {
          endDate.add(1, 'day');
        }
      }

      setValue('endDate', endDate);
    }
  }, [watch('totalDeliveries')]);


  useEffect(() => {
    const startDate = watch('startDate');
    const endDate = watch('endDate');
    const deliveryDays = customer?.deliveryDays;
    const planCount = watch('planCount');

    if (startDate && endDate && deliveryDays && planCount) {
      let totalDeliveries = 0;
      let currentDate = moment(startDate).startOf('day');
      const endMoment = moment(endDate).endOf('day');

      while (currentDate.isSameOrBefore(endMoment, 'day')) {
        const dayOfWeek = currentDate.day();
        if (deliveryDays[dayOfWeek] > 0) {
          totalDeliveries += deliveryDays[dayOfWeek];
        }
        currentDate.add(1, 'day');
      }

      setValue('totalDeliveries', totalDeliveries);
    }
  }, [watch('startDate'), watch('endDate'), watch('planType'), watch('planCount')]);

  const [createTiffinPayment, {
    data: createTiffinPaymentData,
    isSuccess: createTiffinPaymentSuccess,
    error: createTiffinPaymentError,
    isError: createTiffinPaymentIsError,
    isLoading: createTiffinPaymentIsLoading,
  }] = useCreateTiffinPaymentMutation();

  const [updatePayment, {
    data: updatePaymentData,
    isSuccess: updatePaymentSuccess,
    error: updatePaymentError,
    isError: updatePaymentIsError,
    isLoading: updatePaymentIsLoading,
  }] = useUpdatePaymentMutation();

  useEffect(() => {
    if (createTiffinPaymentSuccess && createTiffinPaymentData) {
      successSnackBar({ message: 'Payment created successfully' });
      const paymentData = createTiffinPaymentData.data;
      const updatedRows = [...rows];
      updatedRows.unshift({
        ...paymentData,
        startDate: moment(paymentData?.startDate).format('DD MMM YYYY'),
        endDate: moment(paymentData?.endDate).format('DD MMM YYYY'),
        expectedPaymentDate: paymentData?.expectedPaymentDate ? moment(paymentData?.expectedPaymentDate).format('DD MMM YYYY') : '--',
      });
      setRows(updatedRows.map((item, index) => {
        return {
          ...item,
          index,
        };
      }));
      fetchSubscriber({ id: paymentData?.tiffinSubscriberId });
      reset({
        startDate: moment(paymentData?.endDate).startOf('day').add(1, 'day').valueOf(),
        endDate: null,
        referenceId: null,
        amount: null,
        status: TIFFIN.PAYMENTS.PAID,
        expectedPaymentDate: moment().valueOf(),
        planType: customer?.tiffin?.plan?.type || TIFFIN.PLANS.MONTHLY,
        planCount: customer?.tiffin?.plan?.count || 1,
      });
      handleClose();
    } if (createTiffinPaymentIsError && createTiffinPaymentError) {
      errorSnackBar({ message: createTiffinPaymentError?.data?.errorMessage });
    }
  }, [createTiffinPaymentSuccess, createTiffinPaymentIsError, createTiffinPaymentData, createTiffinPaymentError]);

  useEffect(() => {
    if (updatePaymentSuccess && updatePaymentData) {
      successSnackBar({ message: 'Payment updated successfully' });
      const paymentData = updatePaymentData.data;
      setRows(rows?.map((item) => {
        if (item.id === paymentData.id) {
          return {
            ...item,
            id: paymentData?.id,
            startDate: moment(paymentData?.startDate).format('DD MMM YYYY'),
            endDate: moment(paymentData?.endDate).format('DD MMM YYYY'),
            amount: paymentData?.amount,
            status: paymentData?.status,
            referenceId: paymentData?.referenceId,
            planType: paymentData?.planType,
            planCount: paymentData?.planCount,
            expectedPaymentDate: paymentData?.expectedPaymentDate ? moment(paymentData?.expectedPaymentDate).format('DD MMM YYYY') : '--',
          };
        }
        return item;
      }));
      fetchSubscriber({ id: paymentData?.tiffinSubscriberId });
      reset({
        startDate: moment(paymentData?.endDate).startOf('day').add(1, 'day').valueOf(),
        endDate: null,
        referenceId: null,
        amount: null,
        status: TIFFIN.PAYMENTS.PAID,
        expectedPaymentDate: moment().valueOf(),
        planType: customer?.tiffin?.plan?.type || TIFFIN.PLANS.MONTHLY,
        planCount: customer?.tiffin?.plan?.count || 1,
      });
      handleClose();
    } if (updatePaymentIsError && updatePaymentError) {
      errorSnackBar({ message: updatePaymentError?.data?.errorMessage });
    }
  }, [updatePaymentSuccess, updatePaymentIsError, updatePaymentData, updatePaymentError]);

  useEffect(() => {
    if (action === 'update') {
      reset({
        ...item,
        startDate: moment(item?.startDate).valueOf(),
        endDate: moment(item?.endDate).valueOf(),
        expectedPaymentDate: item?.expectedPaymentDate ? moment(item?.expectedPaymentDate).valueOf() : null,
        planType: item?.planType,
        planCount: item?.planCount,
      });
    }
  }, [action]);

  const checkAndCreatePayment = (userInput) => {
    const pendingPayment = rows.find((row) => row.status === TIFFIN.PAYMENTS.PENDING);
    if (pendingPayment) {
      errorSnackBar({ message: 'Pending payment exists. Please mark it paid or delete to create new one' });
    } else {
      createTiffinPayment({
        tiffinSubscriberId: item?.id,
        data: {
          ...userInput,
          startDate: moment(userInput?.startDate).startOf('day').valueOf(),
          endDate: moment(userInput?.endDate).endOf('day').valueOf(),
          expectedPaymentDate: userInput?.expectedPaymentDate ? moment(userInput?.expectedPaymentDate).endOf('day').valueOf() : null,
        }
      });
    }
  };

  return (
    <Modal
      open={open}
    >
      <Box sx={style}>
        <Grid container spacing={1}>
          <Grid item container xs={12}>
            <Typography variant="h6" sx={{ mt: 1, mb: 1, color: 'secondary.contrastText.main' }}>
              Add payment
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            container
            spacing={3}
            sx={{ mt: 1 }}
            component="form"
            onSubmit={handleSubmit((userInput) => {
              const expectedPaymentDate = userInput?.expectedPaymentDate ? moment(userInput?.expectedPaymentDate).endOf('day').valueOf() : null;
              action === 'add' ? checkAndCreatePayment(userInput) : updatePayment({
                ...userInput,
                id: item?.id,
                startDate: moment(userInput?.startDate).startOf('day').valueOf(),
                endDate: moment(userInput?.endDate).endOf('day').valueOf(),
                expectedPaymentDate,
              });
            })}
          >
            <Grid size={12} container spacing={1}>
              <Grid size={5}>
                <Controller
                  rules={{ required: 'Invalid Plan Type' }}
                  control={control}
                  name="planType"
                  render={({ field }) => {
                    return (
                      <FormControl
                        required={true}
                        fullWidth={true}
                      >
                        <InputLabel
                          id="planType"
                          sx={{
                            color: errors?.['planType']?.message ? 'secondary.contrastText.100' : 'default',
                          }}>
                          Plan Type
                        </InputLabel>
                        <Select
                          id="planType"
                          error={!!errors?.['planType']?.message}
                          label="Meal Plan"
                          onChange={(event) => {
                            field.onChange(event.target.value);
                            if (event.target.value === TIFFIN.PLANS.CUSTOM_DATE || event.target.value === TIFFIN.PLANS.CUSTOM_DELIVERIES) {
                              setValue('planCount', 1);
                            }
                            handleMouseLeave();
                          }}
                          value={field?.value ?? TIFFIN.PLANS.MONTHLY}
                        >
                          {Object.keys(TIFFIN.PLANS).map((planKey) => (
                            <MenuItem key={planKey} value={TIFFIN.PLANS[planKey]} sx={{
                              display: TIFFIN.PLANS[planKey] === TIFFIN.PLANS.CUSTOM_DATE || TIFFIN.PLANS[planKey] === TIFFIN.PLANS.CUSTOM_DELIVERIES ? 'none' : 'block',
                            }}>
                              {capitalizeFirstLetter(TIFFIN.PLANS[planKey])}
                            </MenuItem>
                          ))}
                          <MenuItem onMouseEnter={handleMouseEnter}>
                            Custom
                            <ArrowRightIcon fontSize="small" />
                          </MenuItem>
                          <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMouseLeave}
                            onChange={handleMouseLeave}
                            anchorOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'left',
                            }}
                          >
                            <MenuItem value={TIFFIN.PLANS.CUSTOM_DATE} onClick={() => {
                              field.onChange(TIFFIN.PLANS.CUSTOM_DATE);
                              handleMouseLeave();
                            }}>
                              By Date
                            </MenuItem>
                            <MenuItem value={TIFFIN.PLANS.CUSTOM_DELIVERIES} onClick={() => {
                              field.onChange(TIFFIN.PLANS.CUSTOM_DELIVERIES);
                              handleMouseLeave();
                            }}>
                              By Deliveries
                            </MenuItem>
                          </Menu>
                        </Select>
                      </FormControl>
                    );
                  }}
                />
              </Grid>
              <Grid size={3}>
                <BaseTextField
                  id='totalDeliveries'
                  name="totalDeliveries"
                  label='Total Deliveries'
                  disabled={watch('planType') !== TIFFIN.PLANS.CUSTOM_DELIVERIES}
                  type="number"
                  errors={errors}
                  required={false}
                  validate={register('totalDeliveries', {
                    valueAsNumber: true,
                    required: 'Count is required',
                    min: {
                      value: 1,
                      message: 'Must be greater than 0',
                    },
                  },
                  )}
                />
              </Grid>
              {watch('planType') !== TIFFIN.PLANS.CUSTOM_DATE && watch('planType') !== TIFFIN.PLANS.CUSTOM_DELIVERIES && <Grid size={4}>
                <BaseTextField
                  id='planCount'
                  name="planCount"
                  label={`Total ${watch('planType') === TIFFIN.PLANS.WEEKLY ? 'Weeks' : watch('planType') === TIFFIN.PLANS.BIWEEKLY ? 'Bi Weeks' : 'Months'}`}
                  type="number"
                  disabled={watch('planType') === TIFFIN.PLANS.SINGLE || watch('planType') === TIFFIN.PLANS.CUSTOM}
                  errors={errors}
                  required={false}
                  validate={register('planCount', {
                    valueAsNumber: true,
                    required: 'Count is required',
                    min: {
                      value: 1,
                      message: 'Must be greater than 0',
                    },
                  },
                  )}
                />
              </Grid>}
            </Grid>
            <Grid size={12} container spacing={1}>
              <Grid size={6}>
                <Controller
                  control={control}
                  rules={{ required: 'Invalid Start Date' }}
                  name="startDate"
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      required={true}
                      control={control}
                      onChange={(event) => {
                        onChange(event);
                      }}
                      errors={errors}
                      value={value ? moment(value) : null}
                      label="Start Date"
                      disabled={!isExpired(item)}
                    />
                  )}
                />
              </Grid>
              <Grid size={6}>
                <Controller
                  control={control}
                  rules={{
                    required: 'Invalid End Date',
                    validate: value => {
                      if (moment(value).isBefore(moment(watch('startDate')))) {
                        return 'End Date cannot be before Start Date';
                      }
                    }
                  }}
                  name="endDate"
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      name="endDate"
                      required={true}
                      control={control}
                      disabled={watch('planType') !== TIFFIN.PLANS.CUSTOM_DATE}
                      onChange={(event) => {
                        onChange(event);
                      }}
                      errors={errors}
                      value={value ? moment(value) : null}
                      label="End Date"
                      minDate={moment(watch('startDate'))}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid size={12} container spacing={1}>
              <Grid size={3}>
                <BaseTextField
                  id='amount'
                  name="amount"
                  label="Amount"
                  disabled={viewOnly}
                  errors={errors}
                  type='number'
                  validate={register('amount', {
                    required: 'Amount is required',
                  },
                  )}
                  startAdornment={'$'}
                  inputProps={{ step: '0.01' }} // Allow decimal values
                />
              </Grid>
              <Grid size={3}>
                <Controller
                  control={control}
                  name="status"
                  render={({ field }) => {
                    return (
                      <FormControl
                        required={true}
                        fullWidth={true}
                      >
                        <InputLabel
                          id="status"
                          sx={{
                            color: errors?.['status']?.message ? 'secondary.contrastText.100' : 'default',
                          }}>
                          Status
                        </InputLabel>
                        <Select
                          id="status"
                          name="status"
                          error={!!errors?.['status']?.message}
                          label="Status"
                          onChange={(event) => field.onChange(event.target.value)}
                          value={field?.value ?? TIFFIN.PAYMENTS.PAID}
                        >
                          {Object.keys(TIFFIN.PAYMENTS).map((paymentKey) => <MenuItem key={paymentKey} value={TIFFIN.PAYMENTS[paymentKey]}>{TIFFIN.PAYMENTS[paymentKey]}</MenuItem>)}
                        </Select>
                      </FormControl>
                    );
                  }}
                />
              </Grid>
              <Grid size={6}>
                <Controller
                  control={control}
                  name="expectedPaymentDate"
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      name="expectedPaymentDate"
                      required={false}
                      control={control}
                      onChange={(event) => {
                        onChange(event);
                      }}
                      errors={errors}
                      value={value ? moment(value) : null}
                      label={watch('status') === TIFFIN.PAYMENTS.PAID ? 'Payment Date' : 'Expected Payment Date'}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid size={12} container spacing={1}>
              <BaseTextField
                id='referenceId'
                name="referenceId"
                label="Reference Id"
                disabled={viewOnly}
                errors={errors}
                required={false}
                validate={register('referenceId')}
              />
            </Grid>
            {isExpired(customer) && <Grid size={12} container spacing={1}>
              <Grid container alignItems="baseline" spacing={2}>
                <Grid>
                  <Controller
                    control={control}
                    name="overrideStartDate"
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        sx={{
                          color: 'secondary.contrastText',
                          '&.Mui-checked': {
                            color: 'secondary.contrastText',
                          },
                        }}
                        edge="end"
                        checked={watch('overrideStartDate')}
                        inputProps={{
                          'aria-labelledby': 'switch-list-label-bluetooth',
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid>
                  <Typography id="pick-up-price-type-change">
                    Update subscription start date to this new payment date
                  </Typography>
                </Grid>
              </Grid>
            </Grid>}
            <Grid size={12} justifyContent='flex-end' container spacing={1}>
              <Grid size="auto">
                <CoreButton
                  variant='contained'
                  fullWidth={false}
                  isLoading={createTiffinPaymentIsLoading}
                  onClickHandler={handleClose}
                >Close
                </CoreButton>
              </Grid>
              <Grid size="auto">
                <CoreButton
                  variant='contained'
                  fullWidth={false}
                  isLoading={createTiffinPaymentIsLoading || updatePaymentIsLoading}
                  disabled={!watch('endDate') || !watch('amount')}
                  type="submit"
                >{action}
                </CoreButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal >
  );
}

CustomerPaymentForm.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  viewOnly: PropTypes.bool,
  item: PropTypes.object,
  fetchSubscriber: PropTypes.func,
};