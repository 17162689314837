import React from 'react';
import {
    Drawer,
    Grid2 as Grid,
    Typography,
    IconButton,
    Tooltip,
} from '@mui/material';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { TuiSpinner, DataNotFound, Table, Hooks } from '../../../core';
import { historyApi } from '../../../js/slices/api_slices';
import {getStopStatusChip} from '../customer_utils';
import { getFormattedDateFromUnixTimestamp, getFormattedTimeFromUnixTimestamp } from '../../../js/lib/utils';

const headCells = [{
    id: 'date',
    title: 'Date',
}, {
    id: 'mealPlan',
    title: 'Meal Plan',
}, {
    id: 'status',
    title: 'Status',
}, {
    id: 'address',
    title: 'Address',
}, {
    id: 'deliveredAt',
    title: 'Delivered At',
}, {
    id: 'imageName',
    title: 'Image',
}, {
    id: 'routeName',
    title: 'Route Name',
}, {
    id: 'driverName',
    title: 'Driver Name',
}];

const { useGetHistoryQuery, useLazyGetSingleHistoryQuery } = historyApi;
const { useSnackBarNotification } = Hooks;

export default function CustomerDeliveryHistoryDrawer({ open, handleClose, item }) {
    const [useGetHistoryQueryTrigger] = useLazyGetSingleHistoryQuery();
    const [loading, setLoading] = React.useState(false);
    const { errorSnackBar } = useSnackBarNotification();

    let { data, isLoading } = useGetHistoryQuery({
        page: 1,
        pageSize: 90,
        sortKey: '-date',
        customerId: item?.id,
    }, { refetchOnMountOrArgChange: true, skip: !(item?.id && open) });

    const processedItems = data?.data?.items?.reduce((acc, item) => {
        const { routes, date, id } = item;
        routes.forEach((route) => {
            route.stops.forEach((stop) => {
                if (stop) {
                    acc.push({
                        date: getFormattedDateFromUnixTimestamp(date),
                        mealPlan: stop?.customer?.tiffin?.name || '--',
                        status: getStopStatusChip(stop?.status) || '--',
                        address: stop?.address?.description || '--',
                        deliveredAt: stop?.events?.finish ? getFormattedTimeFromUnixTimestamp(stop?.events?.finish) : '--',
                        imageName: (stop?.imageName &&
                            <IconButton
                                onClick={async () => {
                                    const res = await useGetHistoryQueryTrigger({ id, routeId: route?.id, stopId: stop?.id });
                                    if (res?.data?.data?.imageUrl) {
                                        window.open(res?.data?.data?.imageUrl, '_blank');
                                    } else {
                                        errorSnackBar({ message: 'Image fetch failed' });
                                    }
                                }}
                            >
                                <Tooltip title="View Image">
                                    <OpenInNewIcon />
                                </Tooltip>
                            </IconButton>) || '--',
                        routeName: route?.name || '--',
                        driverName: route?.driver?.firstName + ' ' + route?.driver?.lastName || '--',
                        id
                    });
                }
            });
        });
        return acc;
    }, []);

    const handleDownload = () => {
        setLoading(true);
        const doc = new jsPDF();
        doc.setFontSize(16);
        doc.setTextColor(128, 128, 128);
        let text = 'Delivery History';
        const centerOfPage = 105;
        let y = 10;
        doc.text(text, centerOfPage, y, { align: 'center' });

        y += 10;
        doc.setFontSize(12);
        doc.text(`Customer: ${item?.customer?.firstName} ${item?.customer?.lastName}`, centerOfPage, y, { align: 'center' });
        autoTable(doc, {
            theme: 'grid',
            head: [headCells.map(cell => cell.title)],
            body: processedItems.map(row => headCells.map(cell => row[cell.id])),
            startY: y + 10,
        });
        doc.save('delivery_history.pdf');
        setLoading(false);
    };

    return (
        <Drawer
            anchor="right"
            PaperProps={{
                sx: { width: 1150 },
            }}
            open={open}
            onClose={handleClose}
        >
            <Grid container sx={{ p: 2 }} spacing={1}>
                <Grid container size={12}>
                    <Grid size={1}>
                        <IconButton onClick={handleClose} sx={{ p: 0, pr: 1, pt: 0.5 }}>
                            <ArrowBackIcon />
                        </IconButton>
                    </Grid>
                    <Grid size={4}>
                        <Typography variant="h6" fontWeight="bold">
                            Delivery History
                        </Typography>
                    </Grid>
                    <Grid size={7} justifyContent='flex-end' container alignContent='center'>
                        <IconButton
                            disabled={!data?.data?.items?.length}
                            onClick={handleDownload}>
                            <PictureAsPdfIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                {isLoading && <TuiSpinner />}
                {!isLoading && <Grid size={12}>
                    {!data?.data?.items?.length && <DataNotFound title="Delivery History" />}
                    {data?.data?.items?.length > 0 && (
                        <Table
                            headers={headCells}
                            data={processedItems}
                            setSelectedRow={() => { }}
                        />
                    )}
                </Grid>}
            </Grid>
        </Drawer>
    );
}