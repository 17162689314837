import React, { useEffect } from 'react';
import {
  Grid2 as Grid,
  Divider,
  Typography,
  Checkbox,
} from '@mui/material';
import { Controller } from 'react-hook-form';

import { BaseTextField } from '../../../core/index';

export default function TiffinForm({ register, errors, viewOnly, watch, control, setValue }) {

  useEffect(() => {
    if (!watch('pickUpPriceChange')) {
      setValue('price.pickUp.single', watch('price.delivery.single'));
      setValue('price.pickUp.weekly', watch('price.delivery.weekly'));
      setValue('price.pickUp.monthly', watch('price.delivery.monthly'));
      setValue('price.pickUp.biweekly', watch('price.delivery.biweekly'));
    }
  }, [
    watch('price.delivery.single'), watch('price.delivery.weekly'), watch('price.delivery.monthly'), watch('price.delivery.biweekly')
  ]);
  
  return (
    <Grid
      sx={{
        backgroundColor: 'white',
        borderRadius: '20px',
        border: '1px solid #E0E0E0',
        height: '100%',
      }}>
      <Grid
        container
        spacing={1}
        sx={{
          p: 2,
        }}>
        <Grid container size={12} sx={{ mt: 1 }} spacing={1}>
          <Grid size={12} sx={{ mt: 1 }}>
            <BaseTextField
              id='name'
              name="name"
              label="Name"
              disabled={viewOnly}
              errors={errors}
              value={watch('name')}
              validate={register('name', {
                required: 'Name is required',
                maxLength: {
                  value: 1000,
                  message: 'Length must be less than 500',
                },
              },
              )}
            />
          </Grid>
          <Grid size={12} sx={{ mt: 1 }}>
            <BaseTextField
              multiline
              rows={2}
              name="description"
              id="description"
              label='Description'
              errors={errors}
              disabled={viewOnly}
              required={false}
              validate={register('description', {
                maxLength: {
                  value: 500,
                  message: 'Description must be less than 500',
                },
              })}
            />
          </Grid>
        </Grid>
        <Grid size={12} container spacing={1} sx={{ mt: 2 }}>
          <Grid container alignItems="baseline" spacing={2}>
            <Grid>
              <Controller
                control={control}
                name="pickUpPriceChange"
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    sx={{
                      color: 'secondary.contrastText',
                      '&.Mui-checked': {
                        color: 'secondary.contrastText',
                      },
                    }}
                    edge="end"
                    checked={watch('pickUpPriceChange')}
                    inputProps={{
                      'aria-label': 'switch-list-label-bluetooth',
                    }}
                  />
                )}
              />
            </Grid>
            <Grid>
              <Typography id="pick-up-price-type-change">
                Home Delivery and Pickup prices are different for this tiffin
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container size={12} spacing={1}>
          <Grid size={12} sx={{ mt: 1 }}>
            <Divider textAlign="left">{watch('pickUpPriceChange') ? 'Price (Home Delivery)' : 'Price'}</Divider>
          </Grid>
          <Grid container size={12} sx={{ mt: 1 }} spacing={1}>
            <Grid size={3}>
              <BaseTextField
                id='price.delivery.single'
                name="price.delivery.single"
                label="Single Price"
                disabled={viewOnly}
                errors={errors}
                startAdornment={'$'}
                validate={register('price.delivery.single', {
                  required: 'Price is required',
                  pattern: {
                    value: /^[0-9]*\.?[0-9]+$/,
                    message: 'Price must be a number',
                  },
                },
                )}
              />
            </Grid>
            <Grid size={3}>
              <BaseTextField
                id='price.delivery.weekly'
                name="price.delivery.weekly"
                label="Weekly Price"
                startAdornment={'$'}
                disabled={viewOnly}
                errors={errors}
                validate={register('price.delivery.weekly', {
                  required: 'Price is required',
                  pattern: {
                    value: /^[0-9]*\.?[0-9]+$/,
                    message: 'Price must be a number',
                  },
                },
                )}
              />
            </Grid>
            <Grid size={3}>
              <BaseTextField
                id='price.delivery.biweekly'
                name="price.delivery.biweekly"
                label="Bi-Weekly Price"
                startAdornment={'$'}
                disabled={viewOnly}
                errors={errors}
                validate={register('price.delivery.biweekly', {
                  required: 'Price is required',
                  pattern: {
                    value: /^[0-9]*\.?[0-9]+$/,
                    message: 'Price must be a number',
                  },
                },
                )}
              />
            </Grid>
            <Grid size={3}>
              <BaseTextField
                id='price.delivery.monthly'
                name="price.delivery.monthly"
                label="Monthly Price"
                disabled={viewOnly}
                startAdornment={'$'}
                errors={errors}
                validate={register('price.delivery.monthly', {
                  required: 'Price is required',
                  pattern: {
                    value: /^[0-9]*\.?[0-9]+$/,
                    message: 'Price must be a number',
                  },
                },
                )}
              />
            </Grid>
          </Grid>
        </Grid>
        {watch('pickUpPriceChange') && <Grid item container xs={12} sx={{ mt: 1 }} spacing={1}>
          <Grid size={12} sx={{ mt: 1 }}>
            <Divider textAlign="left">Price (Pickup)</Divider>
          </Grid>
          <Grid container size={12} sx={{ mt: 1 }} spacing={1}>
            <Grid size={3}>
              <BaseTextField
                id='price.pickUp.single'
                name="price.pickUp.single"
                label="Single Price"
                disabled={viewOnly}
                errors={errors}
                validate={register('price.pickUp.single', {
                  required: 'Price is required',
                  pattern: {
                    value: /^[0-9]*\.?[0-9]+$/,
                    message: 'Price must be a number',
                  },
                },
                )}
              />
            </Grid>
            <Grid size={3}>
              <BaseTextField
                id='price.pickUp.weekly'
                name="price.pickUp.weekly"
                label="Weekly Price"
                disabled={viewOnly}
                errors={errors}
                validate={register('price.pickUp.weekly', {
                  required: 'Price is required',
                  pattern: {
                    value: /^[0-9]*\.?[0-9]+$/,
                    message: 'Price must be a number',
                  },
                },
                )}
              />
            </Grid>
            <Grid size={3}>
              <BaseTextField
                id='price.pickUp.biweekly'
                name="price.pickUp.biweekly"
                label="Bi-Weekly Price"
                disabled={viewOnly}
                errors={errors}
                validate={register('price.pickUp.biweekly', {
                  required: 'Price is required',
                  pattern: {
                    value: /^[0-9]*\.?[0-9]+$/,
                    message: 'Price must be a number',
                  },
                },
                )}
              />
            </Grid>
            <Grid size={3}>
              <BaseTextField
                id='price.pickUp.monthly'
                name="price.pickUp.monthly"
                label="Monthly Price"
                disabled={viewOnly}
                errors={errors}
                validate={register('price.pickUp.monthly', {
                  required: 'Weekly Price is required',
                  pattern: {
                    value: /^[0-9]*\.?[0-9]+$/,
                    message: 'Price must be a number',
                  },
                },
                )}
              />
            </Grid>
          </Grid>
        </Grid>}
        <Grid size={12} sx={{ mt: 2 }}>
          <Divider />
        </Grid>
        <Grid size={12} container spacing={1} sx={{ mt: 2 }}>
          <Grid container alignItems="baseline" spacing={2}>
            <Grid item>
              <Controller
                control={control}
                name="showInCustomerPortal"
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    sx={{
                      color: 'secondary.contrastText',
                      '&.Mui-checked': {
                        color: 'secondary.contrastText',
                      },
                    }}
                    edge="end"
                    checked={watch('showInCustomerPortal')}
                    onChange={field.onChange}
                    inputProps={{
                      'aria-labelledby': 'switch-list-label-bluetooth',
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item>
              <Typography id="pick-up-price-type-change">
                Show in Customer Portal
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
