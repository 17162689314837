const HOSTNAME = process.env.REACT_APP_HOSTNAME;
const API_PREFIX = 'api';
const API_VERSION = 'v1';
export const SIDE_DRAWER_WIDTH = 220;
export const SERVER_URL = `${HOSTNAME}/${API_PREFIX}/${API_VERSION}`;
export const START_YEAR = 2022;
export const TURMITECH_WEBSITE_URL = 'https://www.turmitech.com';

export const APIS = {
  CUSTOMER_BULK_OPERATIONS: {
    DELETE: 'delete',
    ADD_HOLD: 'addHold',
    CHANGE_MEAL_PLAN: 'changeMealPlan',
    CHANGE_ROUTE: 'changeRoute',
    CANCEL_SUBSCRIPTION: 'cancelSubscription',
  },
  EXPENSE_BULK_OPERATIONS: {
    DELETE: 'delete',
    UPDATE: 'update',
  }
};

export const CURRENCY = {
  USD: 'USD',
  CAD: 'CAD',
  INR: 'INR',
};

export const SALARY_TYPES = {
  MONTHLY: 'Monthly',
  HOURLY: 'Hourly',
  DAILY: 'Daily',
  PER_HOUR: 'Per Hour',
  PER_PACKAGE: 'Per Package',
  PER_STOP: 'Per Stop',
  PER_KM: 'Per Kilometer',
  PER_ROUTE: 'Per Route',
};

export const RESTAURANT = {
  SERVICE: {
    DINE_IN: 'DINE_IN',
    SELF: 'SELF',
  },
};

export const MENU_ITEMS = {
  CURRENCY: {
    USD: 'USD',
    CAD: 'CAD',
  },
  VIEWS: {
    TILES: 'tile',
    LIST: 'list',
  },
  CLASSIFICATION: {
    NONE: 'None',
    VEG: 'Veg',
    NON_VEG: 'Non-Veg',
    VEGAN: 'Vegan',
    GLUTEN_FREE: 'Gluten Free',
    EGG: 'Egg',
    ALCOHOLIC: 'Alcoholic',
    NON_ALCOHOLIC: 'Non-Alcoholic',
  },
};

export const DELIVERY_METHOD = {
  HOME_DELIVERY: 'Home Delivery',
  PICK_UP: 'Pick up',
};

export const ORDERS = {
  STATUS: {
    COMPLETED: 'Completed',
    PENDING: 'Pending',
    CANCELLED: 'Cancelled',
    FAILED: 'Failed',
  },
};

export const UNITS = {
  BOWL: 'BOWL',
  GRAMS: 'GRAMS',
  OUNCES: 'OUNCES',
  POUNDS: 'POUNDS',
  PIECES: 'PIECES',
  SLICES: 'SLICES',
  CUPS: 'CUPS',
  TABLESPOONS: 'TABLE-SPOONS',
  TEASPOONS: 'TEA-SPOONS',
};

export const USERS = {
  STATUS: {
    PENDING_APPROVAL: 'Pending_Approval',
    ARCHIVED: 'Archived',
    ACTIVE: 'Active',
    AWAY: 'Away',
    BLOCKED: 'Blocked',
    PROFILE_INCOMPLETE: 'Profile_Incomplete',
    WAITING: 'Waiting',
  },
  TYPES: {
    EMPLOYEE: {
      COOK: 'Cook',
      DRIVER: 'Driver',
      PACKER: 'Packer',
      MANAGER: 'Manager',
      ALL: ['Cook', 'Driver', 'Packer', 'Manager'],
    },
  },
};
export const TIFFIN = {
  STATUS: {
    IN_PROGRESS: 'In Progress',
    ACTIVE: 'Active',
    EXPIRING_SOON: 'Expiring Soon',
    EXPIRED: 'Expired',
    CANCEL: 'Cancel',
    HOLD: 'Hold',
    NOT_STARTED: 'Not Started',
  },
  SHIPPING_STATUS: {
    PENDING: 'Pending',
    DELIVERED: 'Delivered',
    PICKED_UP: 'Picked Up',
    NONE: 'None',
    HOLD: 'Hold',
    NON_DELIVERY_DAY: 'Non Delivery Day',
  },
  PLANS: {
    SINGLE: 'single',
    WEEKLY: 'weekly',
    BIWEEKLY: 'biweekly',
    MONTHLY: 'monthly',
    CUSTOM_DATE: 'custom_date',
    CUSTOM_DELIVERIES: 'custom_deliveries',
  },
  DELIVERY_ROUTES_STATUS: {
    NOT_READY: 'Not Ready',
    READY: 'Ready',
    IN_PROGRESS: 'In Progress',
    COMPLETED: 'Completed',
    CANCELLED: 'Cancelled',
  },
  DELIVERY_ROUTES_STOP_STATUS: {
    PENDING: 'Pending',
    ON_THE_WAY: 'On The Way',
    DELIVERED: 'Delivered',
    FAILED: 'Failed',
    CANCELLED: 'Cancelled',
  },
  PAYMENTS: {
    PAID: 'Paid',
    PENDING: 'Pending',
  }
};

export const ROUTE = {
  DELIVERY_AREA_TYPES: {
    POLYGON: 'Polygon',
  }
};

export const DAYS = [
  {
    name: 'Sunday',
    shortName: 'Sun',
    label: 'S',
    value: 0,
  },
  {
    name: 'Monday',
    shortName: 'Mon',
    label: 'M',
    value: 1,
  },
  {
    name: 'Tuesday',
    shortName: 'Tue',
    label: 'T',
    value: 2,
  },
  {
    name: 'Wednesday',
    shortName: 'Wed',
    label: 'W',
    value: 3,
  },
  {
    name: 'Thursday',
    shortName: 'Thu',
    label: 'T',
    value: 4,
  },
  {
    name: 'Friday',
    shortName: 'Fri',
    label: 'F',
    value: 5,
  },
  {
    name: 'Saturday',
    shortName: 'Sat',
    label: 'S',
    value: 6,
  },
];

export const TIMEZONES = {
  Edmonton: 'Mountain Standard Time',
  Toronto: 'Eastern Standard Time',
  Vancouver: 'Pacific Standard Time',
};

export const STORAGE_KEYS = {
  CUSTOMER: 'customer',
  CUSTOMER_FILTER: 'customer_filter',
  CUSTOMER_PAGINATION: 'customer_pagination',
  ROUTE: 'route',
  ROUTE_FILTER: 'route_filter',
  TIFFIN: 'tiffin',
  EMPLOYEE: 'employee',
  EMPLOYEE_PAGINATION: 'employee_pagination',
  EXPENSE: 'expense',
  EXPENSE_FILTER: 'expense_filter',
  LEADS_FILTER: 'leads_filter',
};


export const LEADS = {
  STATUS: {
    NEW: 'New',
    ACCEPTED: 'Accepted',
    REJECTED: 'Rejected',
  }
};