import React, { useState, useEffect } from 'react';
import { useFieldArray, Controller, useForm } from 'react-hook-form';
import {
  FormLabel,
  Grid2 as Grid, Box,
  FormHelperText,
  Modal,
  Typography,
  IconButton,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  Divider,
  Checkbox
} from '@mui/material';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditOutlined from '@mui/icons-material/EditOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import {
  DataGrid,
} from '@mui/x-data-grid';

import { BaseTextField, CoreButton, Hooks, TuiAutoComplete, ToggleDays } from '../../../core';
import { UNITS, DAYS } from '../../../js/lib/constants';
import { tiffinsApi } from '../../../js/slices/api_slices';

const { useSnackBarNotification, useDebounce } = Hooks;
const { useGetTiffinItemsQuery, useCreateTiffinItemMutation } = tiffinsApi;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: '1px solid',
  borderRadius: '20px',
  borderShadow: 24,
  p: 2,
};

export default function TiffinGoodForm({ control, setValue }) {
  const { fields, remove, append, update } = useFieldArray({
    control,
    name: 'items',
  });

  const [open, setOpen] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [customDaysAndQuantityChecked, setCustomDaysAndQuantityChecked] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setEditIndex(null);
  };
  const handleOpen = () => setOpen(true);

  const { successSnackBar } = useSnackBarNotification();
  const [createItem, setCreateItem] = useState(false);

  const [inputItemName, setInputItemName] = useState('');
  const debouncedTiffinInput = useDebounce(inputItemName, 250);

  const { data: tiffinItems,
    isSuccess: tiffinItemFetchSuccess,
    isLoading: tiffinItemFetchLoading } = useGetTiffinItemsQuery({
      page: 1,
      pageSize: 20,
      contains: { name: debouncedTiffinInput },
    }, { refetchOnMountOrArgChange: true, skip: debouncedTiffinInput ? false : true });

  const [addNewTiffinItem] = useCreateTiffinItemMutation();

  const [rows, setRows] = useState(fields?.map((field) => ({
    ...field,
    days: field?.days ? Object.keys(field.days).map((day) => `${DAYS.find(({ value }) => value === parseInt(day)).shortName}:${field.days[day]}`) : [],
  })));

  useEffect(() => {
    setRows(fields.map((item) => ({
      ...item,
      days: item?.days ? Object.keys(item.days).map((day) => `${DAYS.find(({ value }) => value === parseInt(day)).shortName}:${item.days[day]}`) : [],
    })));
  }, [fields]);

  useEffect(() => {
    if (tiffinItemFetchSuccess && tiffinItems?.data?.items?.length === 0) {
      setCreateItem(true);
    }
  }, [tiffinItemFetchSuccess]);

  const deleteItemHandler = (id) => {
    const itemIndex = fields.findIndex((item) => item.id === id);
    if (itemIndex > -1) {
      remove(itemIndex);
      setValue('itemsChanged', true);
    }
  };

  const removeAll = () => {
    remove();
    setRows([]);
    setValue('itemsChanged', true);
  };

  const columns = [
    {
      field: 'name',
      align: 'left',
      headerAlign: 'left',
      headerName: 'Name',
      width: 120,
      editable: false,
    },
    {
      field: 'unit',
      align: 'left',
      headerAlign: 'left',
      headerName: 'Unit',
      width: 100,
      editable: false,
    },
    {
      field: 'days',
      headerName: 'Days & Quantity',
      width: 300,
      editable: false,
      type: 'multiselect',
      valueOptions: DAYS.map(({ shortName }) => shortName),
    },
    {
      field: 'action',
      type: 'actions',
      headerName: 'Actions',
      width: 120,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [
          <IconButton
            key={`edit-${id}`}
            edge="end"
            aria-label="edit"
            onClick={() => {
              const item = fields.find((item) => item.id === id);
              const checkAllDaysSame = Object.values(item?.days).every((val) => val === item?.days[0]);
              setCustomDaysAndQuantityChecked(!checkAllDaysSame);
              setInputItemName(item?.name);
              nestedReset({
                name: item?.name,
                quantity: checkAllDaysSame ? item?.days[0] : '',
                unit: item?.unit,
                days: item?.days
              });
              setEditIndex(fields.findIndex((item) => item.id === id));
              handleOpen();
            }
            }>
            <EditOutlined />
          </IconButton>,
          <IconButton
            key={`delete-${id}`}
            edge="end"
            aria-label="delete"
            onClick={() => deleteItemHandler(id)}
          >
            <DeleteOutlineIcon />
          </IconButton>,
        ];
      },
    },
  ];

  const processRowUpdate = (newRow) => {
    const itemIndex = fields.findIndex((item) => item.id === newRow.id);
    if (itemIndex > -1) {
      update(itemIndex, newRow);
      const updatedRow = { ...newRow, isNew: false };
      const updatedRows = rows.map((row) => (row.id === newRow.id ? updatedRow : row));
      setRows(updatedRows);
      return updatedRow;
    }
  };

  // Initialize useForm for the nested form
  const {
    handleSubmit: handleNestedSubmit,
    control: nestedControl,
    watch: nestedWatch,
    setValue: setNestedValue,
    reset: nestedReset,
    register: nestedRegister,
    formState: { errors: nestedErrors },
  } = useForm({
    defaultValues: {
      name: '',
      quantity: '',
      unit: '',
      days: {
        0: 1,
        1: 1,
        2: 1,
        3: 1,
        4: 1,
        5: 1,
        6: 1,
      }
    },
  });

  const resetFields = () => {
    nestedReset({
      name: '',
      quantity: '',
      unit: '',
      days: {
        0: 1,
        1: 1,
        2: 1,
        3: 1,
        4: 1,
        5: 1,
        6: 1,
      }
    });
    setInputItemName('');
    setEditIndex(null);
    setCustomDaysAndQuantityChecked(false);
  }

  const onSubmitNestedForm = (data, e) => {
    if (editIndex !== null) {
      update(editIndex, data);
      successSnackBar({ message: 'Item updated successfully' });
    } else {
      append(data);
      successSnackBar({ message: 'Item added successfully' });
    }
    if (createItem) {
      delete data?.days;
      data.quantity = Number(data?.quantity ?? 1);
      addNewTiffinItem(data);
    }
    resetFields();
    setValue('itemsChanged', true);
  };

  useEffect(() => {
    if (!customDaysAndQuantityChecked) {
      const quantity = Number(nestedWatch('quantity'));
      setNestedValue('days', {
        0: quantity,
        1: quantity,
        2: quantity,
        3: quantity,
        4: quantity,
        5: quantity,
        6: quantity,
      });
    }
  }, [nestedWatch('quantity')]);


  const formModal = <Modal
    open={open}
    keepMounted
    aria-labelledby="modal-modal-item-form"
  >
    <Box sx={style}>
      <Typography variant="h6" sx={{ mt: 1, mb: 1, color: 'secondary.contrastText.main' }}>
        {editIndex !== null ? 'Edit' : 'Add'} item
      </Typography>
      <Grid component='form' container size={12} onSubmit={(e) => {
        e.stopPropagation();
        handleNestedSubmit(onSubmitNestedForm)(e);
      }}>
        <Grid container spacing={2} size={12}>
          <Grid size={12} container spacing={1} sx={{ mt: 1 }}>
            <Grid size={5}>
              <Controller
                control={nestedControl}
                name="name"
                render={({ field }) => (
                  <TuiAutoComplete
                    id="name"
                    name="name"
                    freeSolo
                    errors={nestedErrors}
                    onChange={(event, item) => {
                      if (item) {
                        setNestedValue('name', item?.name);
                        setNestedValue('quantity', item?.quantity);
                        setNestedValue('unit', UNITS[item?.unit]);
                        field.onChange(item?.name);
                      } else {
                        setNestedValue('name', '');
                        setNestedValue('quantity', 1);
                        setNestedValue('unit', '');
                        setCustomDaysAndQuantityChecked(false);
                        field.onChange('');
                      }
                    }}
                    inputValue={inputItemName}
                    onInputChange={(event, newInputValue) => {
                      setInputItemName(newInputValue);
                      field.onChange(newInputValue);
                    }}
                    labelKey='label'
                    helperText="e.g Rice"
                    label="Name"
                    options={tiffinItems?.data?.items.map(({ name, quantity, unit }) => ({
                      name,
                      quantity,
                      unit,
                      label: `${name}-${quantity}-${unit}`,
                    }))}
                  />
                )}
              />
            </Grid>
            <Grid size={3}>
              <BaseTextField
                id='quantity'
                label="Quantity"
                type="number"
                errors={nestedErrors}
                disabled={customDaysAndQuantityChecked}
                required={!customDaysAndQuantityChecked}
                inputProps={{
                  min: 0,
                  style: { textAlign: 'center' },
                  step: '0.5',
                }}
                validate={nestedRegister('quantity')}
              />
            </Grid>
            <Grid size={4}>
              <Controller
                control={nestedControl}
                name="unit"
                render={({ field }) => (
                  <FormControl
                    required={true}
                    fullWidth
                  >
                    <InputLabel
                      sx={{
                        color: nestedErrors?.['unit']?.message ? 'secondary.contrastText.100' : 'default',
                      }}>
                      Unit
                    </InputLabel>
                    <Select
                      {...field}
                      id="unit"
                      error={!!nestedErrors?.['unit']?.message}
                      label="Unit"
                      onChange={(event) => field.onChange(event.target.value)}
                      value={field?.value}
                    >
                      {Object.values(UNITS).map((item) => <MenuItem key={item} value={item}>{item}</MenuItem>)}
                    </Select>
                    <Typography fontSize={12} mt={1} ml={2} color='secondary.contrastText.100'>{nestedErrors?.type?.message}</Typography>
                  </FormControl>
                )}
              />
            </Grid>
          </Grid>
          <Grid size={12} container spacing={1} sx={{ mt: 2 }}>
            <Grid container alignItems="baseline" spacing={2}>
              <Grid>
                <Checkbox
                  sx={{
                    color: 'secondary.contrastText',
                    '&.Mui-checked': {
                      color: 'secondary.contrastText',
                    },
                  }}
                  edge="end"
                  onChange={(e) => setCustomDaysAndQuantityChecked(e.target.checked)}
                  checked={customDaysAndQuantityChecked}
                  inputProps={{
                    'aria-labelledby': 'switch-list-label-bluetooth',
                  }}
                />
              </Grid>
              <Grid>
                <Typography id="pick-up-price-type-change">
                  Advanced Days & Quantity
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {customDaysAndQuantityChecked && <Grid size={12}>
            <Controller
              rules={{ required: 'Select valid days and quantity' }}
              control={nestedControl}
              name="days"
              render={({ field }) => (
                <FormControl fullWidth error={!!nestedErrors?.days?.message}>
                  <Grid container>
                    <Grid size={2}>
                      <FormLabel component="legend">Days & Quantity</FormLabel>
                    </Grid>
                    <Grid size={10} container justifyContent='flex-end'>
                      <ToggleDays field={field} existingDays={field?.value} decimalAllowed={true} />
                      <FormHelperText>{nestedErrors?.days?.message}</FormHelperText>
                    </Grid>
                  </Grid>
                </FormControl>
              )}
            />
          </Grid>}
          <Grid size={12}>
            <Divider />
          </Grid>
          <Grid size={12} justifyContent='flex-end' container spacing={1}>
            <Grid size="auto">
              <CoreButton
                variant='outlined'
                fullWidth={false}
                isLoading={tiffinItemFetchLoading}
                onClickHandler={handleClose}
                startIcon={<CloseOutlinedIcon />}
              >Close
              </CoreButton>
            </Grid>
            <Grid size="auto">
              <CoreButton
                variant='contained'
                fullWidth={false}
                isLoading={tiffinItemFetchLoading}
                type="submit"
                disabled={!nestedWatch('name') || !nestedWatch('unit') || !nestedWatch('days')}
                startIcon={<SaveOutlinedIcon />}
              >Save
              </CoreButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  </Modal>;

  return (<>
    <Grid
      sx={{
        backgroundColor: 'white',
        borderRadius: '20px',
        border: '1px solid #E0E0E0',
        height: '100%',
      }}>
      <Grid size={12} container spacing={1}
        sx={{
          p: 2,
          height: 'inherit',
        }}>
        <Grid container sx={{ p: 2 }} spacing={1}>
          <Grid container size={12}>
            <Grid size={8}>
              <Typography color="secondary.contrastText.main">List of the goods</Typography>
            </Grid>
            <Grid container size={4} justifyContent='flex-end'>
              <Grid size="auto" container justifyContent='flex-end' sx={{
                height: 30,
              }}>
                <CoreButton
                  variant='contained'
                  fullWidth={false}
                  startIcon={<AddOutlinedIcon />}
                  onClickHandler={() => {
                    resetFields();
                    handleOpen();
                  }}
                >Add Item
                </CoreButton>
              </Grid>
            </Grid>
          </Grid>
          {fields.length > 0 && <Grid size={12} container>
            <Grid size={6}>
              <Typography fontWeight="bold" variant="h6">Items</Typography>
            </Grid>
            <Grid size={6} container justifyContent='flex-end'>
              <CoreButton
                disableRipple
                fullWidth={false}
                onClickHandler={() => removeAll()}
                sx={{
                  color: 'secondary.contrastText',
                  textDecoration: 'underline',
                }}
              >Clear Items</CoreButton>
            </Grid>
          </Grid>}
          <Grid size={12}>
            <Box
              sx={{
                mt: 1,
                'height': '65vh',
                'width': '100%',
                '& .actions': {
                  color: 'text.secondary',
                },
                '& .textPrimary': {
                  color: 'text.primary',
                },
              }}
            >
              <DataGrid
                rows={rows}
                columns={columns}
                editMode="row"
                processRowUpdate={processRowUpdate}
                onProcessRowUpdateError={(error) => {
                  console.log({ error });
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    {formModal}
  </>
  );
}