import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Alert, Box, Grid, Typography, IconButton, Divider, Button, Chip, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import RefreshIcon from '@mui/icons-material/Refresh';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

import BasePagination from '../../../core/base_pagination';
import CustomerHoldForm from '../components/customer_hold_form';
import { tiffinsApi, deliveryRoutesApi } from '../../../js/slices/api_slices';
import CustomerListComponent from '../components/customer_list_component';
import { TuiAppBar, Hooks, ConfirmationModal, TuiSpinner, TuiAutoComplete, CoreButton, DataNotFound, Footer } from '../../../core';
import CustomerFilterModal from '../components/customer_filter_modal';
import { APIS, DELIVERY_METHOD, STORAGE_KEYS, TIFFIN, DAYS } from '../../../js/lib/constants';
import CloseIcon from '@mui/icons-material/Close';
import { getFormattedDateFromUnixTimestamp, getFormattedPhoneNumber, getFormattedDateTimeFromUnixTimestamp } from '../../../js/lib/utils';

const { useGetTiffinSubscribersQuery,
  useBulkOperationMutation,
  useLazyGetTiffinSubscribersQuery,
  useGetTiffinsQuery,
  useGetTiffinSubscriberStatsQuery } = tiffinsApi;
const { useResponsivePageSize, usePageNumberAndSearch, useDebounce, useSnackBarNotification } = Hooks;
const { useGetDeliveryRoutesQuery } = deliveryRoutesApi;

const showAllTiffinsText = 'All';
const showSpecialTiffinsText = 'Special';
const showNonSpecialTiffinsText = 'Non Special';
const exportHeaders = ['Name', 'Email', 'Phone', 'Unit', 'Address', 'Start Date', 'End Date', 'Price', 'Route Name', 'Meal Plan Name', 'Meal Plan Frequency', 'Status', 'Delivery Days', 'Comment', 'Delivery Instructions', 'Created Date'];

export default function CustomersView() {
  const settings = useSelector((store) => store?.settings?.settings);
  const [pageSize, setPageSize] = useResponsivePageSize(localStorage.getItem(STORAGE_KEYS.CUSTOMER_PAGINATION) ?
    JSON.parse(localStorage.getItem(STORAGE_KEYS.CUSTOMER_PAGINATION))?.pageSize : 10);
  const [selectedItems, setSelectedItems] = useState([]);
  const roles = useSelector((store) => store?.roles.roles);
  const [newRoute, setNewRoute] = useState(null);
  const [newRouteInputValue, setNewRouteInputValue] = useState('');
  const [newTiffin, setNewTiffin] = useState(null);
  const [newTiffinInputValue, setNewTiffinInputValue] = useState('');
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [selectedExportHeaders, setSelectedExportHeaders] = useState(exportHeaders);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const [showCancelConfirmationModal, setShowCancelConfirmationModal] = useState(false);
  const [showChangeRouteConfirmationModal, setShowChangeRouteConfirmationModal] = useState(false);
  const [showChangeTiffinConfirmationModal, setShowChangeTiffinConfirmationModal] = useState(false);
  const [showAddHoldConfirmationModal, setShowAddHoldConfirmationModal] = useState(false);
  const navigate = useNavigate();
  const [page, setPage, searchText, setSearchText] = usePageNumberAndSearch();
  const [rows, setRows] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const { errorSnackBar, successSnackBar } = useSnackBarNotification();
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [filterCount, setFilterCount] = useState(0);
  const [customerCreateLimitLeft, setCustomerCreateLimitLeft] = useState(0);
  let storageFilter = sessionStorage.getItem(STORAGE_KEYS.CUSTOMER_FILTER);
  if (storageFilter) {
    storageFilter = JSON.parse(storageFilter);
  }
  const [filterObject, setFilterObject] = useState({
    'status': storageFilter?.status || '',
    'shipping': storageFilter?.shipping || '',
    'route': storageFilter?.route || '',
    'tiffin': storageFilter?.tiffin || '',
    'itemsChanged': storageFilter?.itemsChanged || null,
    'paymentStatus': storageFilter?.payment || '',
    'date': storageFilter?.date || null,
  });

  const debouncedRouteSearchTerm = useDebounce(newRouteInputValue, 500);
  const allDeliveryRoutesStatus = { ...TIFFIN.DELIVERY_ROUTES_STATUS };
  delete allDeliveryRoutesStatus.CANCELLED;

  let { data: deliveryRouteData, isLoading: deliveryRouteLoading } = useGetDeliveryRoutesQuery({
    contains: { name: debouncedRouteSearchTerm },
    status: Object.values(allDeliveryRoutesStatus).join(','),
  }, { refetchOnMountOrArgChange: true, skip: !(showChangeRouteConfirmationModal || showFilterModal) });

  deliveryRouteData = deliveryRouteData?.data?.items?.map((item) => {
    return {
      ...item,
      label: `${item?.name} - ${item?.driver?.firstName}`,
    };
  }) || [];

  deliveryRouteData = [{
    id: null,
    label: 'None',
    name: 'None',
    driver: {
      id: null,
      firstName: 'None',
      lastName: 'None'
    }
  }, ...deliveryRouteData];

  const debouncedTiffinSearchTerm = useDebounce(newTiffinInputValue, 500);

  let { data: tiffinData, isLoading: tiffinDataLoading } = useGetTiffinsQuery({
    contains: { name: debouncedTiffinSearchTerm },
  }, { refetchOnMountOrArgChange: true, skip: !showChangeTiffinConfirmationModal });

  const debouncedSearchTerm = useDebounce(searchText, 500);
  const customerFetchQuery = {
    'contains': {
      'customer.firstName': debouncedSearchTerm,
      'customer.lastName': debouncedSearchTerm,
      'customer.address.description': debouncedSearchTerm,
      'customer.unit': debouncedSearchTerm,
      'customer.email': debouncedSearchTerm,
      'customer.phoneNumber.number': debouncedSearchTerm,
      'comment': debouncedSearchTerm,
      'deliveryInstructions': debouncedSearchTerm,
      'tiffin.name': debouncedSearchTerm,
    },
    'sortKey': '+customer.firstName|+customer.lastName|+customer.address.description',
    'status': filterObject?.status,
    'date': filterObject?.date,
    'shipping': filterObject?.shipping,
    'route.id': filterObject?.['route'],
    'tiffin.id': filterObject?.['tiffin'],
    'itemsChanged': filterObject?.itemsChanged === showAllTiffinsText ? null :
      filterObject?.itemsChanged === showSpecialTiffinsText ? true :
        filterObject?.itemsChanged === showNonSpecialTiffinsText ? false : null,
    'paymentStatus': filterObject?.paymentStatus,
  };
  const { data, isFetching, isSuccess, refetch } = useGetTiffinSubscribersQuery({
    page,
    pageSize,
    ...customerFetchQuery,
  }, { refetchOnMountOrArgChange: true });
  let {
    data: customerStatsData,
    isFetching: customerStatsLoading,
    refetch: refetchCustomerStats,
  } = useGetTiffinSubscriberStatsQuery({
    page,
    pageSize,
    ...customerFetchQuery,
  }, { refetchOnMountOrArgChange: true });
  const [trigger] = useLazyGetTiffinSubscribersQuery({});

  customerStatsData = customerStatsData?.data?.items?.[0]

  const [bulkOperation, {
    isLoading: bulkLoading,
    isSuccess: bulkSuccess,
    isError: bulkIsError,
    error: bulkError,
  }] = useBulkOperationMutation();

  useEffect(() => {
    if (isSuccess && !isFetching) {
      setRows(data?.data?.items);
    }
  }, [isFetching, isSuccess, data?.data?.items]);

  useEffect(() => {
    if (customerStatsData) {
      const { activeCount, expiredCount, holdCount, expiringSoonCount, notStartedCount, cancelledCount } = customerStatsData;
      const totalCountWithoutCancelled = activeCount + expiredCount + holdCount + expiringSoonCount + notStartedCount;
      const customerCreateLimitLeft = settings?.plan?.customers?.max - totalCountWithoutCancelled;
      setCustomerCreateLimitLeft(customerCreateLimitLeft);
    }
  }, [bulkSuccess, customerStatsData]);

  const fetchAllPages = async (page = 1, pageSize = 200) => {
    const response = await trigger({
      page,
      pageSize,
      ...customerFetchQuery,
    });

    let data = response?.data?.data;
    const totalCount = data.totalCount;
    const totalPages = Math.ceil(totalCount / pageSize);

    if (page < totalPages) {
      const nextPageData = await fetchAllPages(page + 1, pageSize);
      data = { ...data, items: [...data.items, ...nextPageData.items] };
    }

    return data;
  };

  const selectAllOtherCustomers = async () => {
    setShowLoading(true);
    const allCustomers = await fetchAllPages();
    const allCustomerIds = allCustomers?.items?.map((item) => item?.id);
    setSelectedItems(allCustomerIds);
    setRows(allCustomers?.items);
    setShowLoading(false);
  };

  useEffect(() => {
    if (selectedItems.length && rows?.length && (selectedItems?.length === rows?.length || selectedItems?.length === data?.data?.totalCount)) {
      setSelectAllChecked(true);
    } else if (selectedItems.length && rows?.length && selectedItems?.length !== rows?.length) {
      setSelectAllChecked(false);
    }
  }, [selectedItems])

  useEffect(() => {
    if (!showLoading) {
      if (bulkSuccess) {
        successSnackBar({ message: 'Customers updated successfully' });
        refetchCustomerStats();
        refetch();
      } if (bulkIsError && bulkError) {
        errorSnackBar({ message: bulkError?.data?.errorMessage });
      }
    }
  }, [bulkSuccess, bulkIsError, bulkError]);

  const selectAllHandler = (e) => {
    if (e.target.checked) {
      setSelectedItems(rows?.map((row) => row?.id));
      setSelectAllChecked(true);
    } else {
      setSelectAllChecked(false);
      setSelectedItems([]);
    }
  };

  const filterModal =
    <CustomerFilterModal
      open={showFilterModal}
      filterObject={filterObject}
      handleClose={() => setShowFilterModal(false)}
      setFilterCount={setFilterCount}
      setFilterObject={setFilterObject}
      setPage={setPage}
      data={{ routeData: deliveryRouteData }}
    />;

  const deleteConfirmationModal = <ConfirmationModal
    open={showDeleteConfirmationModal}
    handleClose={() => setShowDeleteConfirmationModal(false)}
    title={<Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant='h6'>{`Are you sure want to delete ${selectedItems?.length} customer?`}</Typography>
      </Grid>
    </Grid>}
    type="Delete"
    actionHandler={async () => {
      setShowLoading(true);
      bulkOperation({ operation: APIS.CUSTOMER_BULK_OPERATIONS.DELETE, ids: [...selectedItems] });
      setSelectedItems([]);
      setSelectAllChecked(false);
      setShowLoading(false);
      setShowDeleteConfirmationModal(false);
    }}
  />;

  const cancelConfirmationModal = <ConfirmationModal
    open={showCancelConfirmationModal}
    handleClose={() => setShowCancelConfirmationModal(false)}
    title={<Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant='h6'>{`Are you sure want to cancel ${selectedItems?.length} customer?`}</Typography>
      </Grid>
    </Grid>}
    type="Update"
    actionHandler={async () => {
      setShowCancelConfirmationModal(false);
      setShowLoading(true);
      bulkOperation({ operation: APIS.CUSTOMER_BULK_OPERATIONS.CANCEL_SUBSCRIPTION, ids: [...selectedItems] });
      setSelectedItems([]);
      setSelectAllChecked(false);
      setShowLoading(false);
      setShowCancelConfirmationModal(false);
    }}
  />;

  const changeRouteConfirmationModal = <ConfirmationModal
    open={showChangeRouteConfirmationModal}
    handleClose={() => {
      setShowChangeRouteConfirmationModal(false)
    }}
    title={<Grid container spacing={2}>
      {deliveryRouteLoading && <TuiSpinner />}
      {!deliveryRouteLoading && <><Grid item xs={12}>
        <Typography variant='h6'>Select new route</Typography>
      </Grid>
        <Grid item xs={12}>
          <TuiAutoComplete
            id="newRoute"
            name="newRoute"
            disabled={deliveryRouteLoading || !deliveryRouteData?.length}
            loading={deliveryRouteLoading}
            freeSolo
            onChange={(event, item) => {
              setNewRoute(item);
            }}
            inputValue={newRouteInputValue}
            onInputChange={(event, newInputValue) => {
              if (newInputValue) {
                const existingItem = deliveryRouteData?.find((item) => item?.label === newInputValue);
                setNewRouteInputValue(existingItem?.name);
              } else {
                setNewRouteInputValue('');
              }
            }}
            value={newRoute}
            label="New Route"
            labelKey='label'
            noOptionsText="No Item Found"
            helperText={!deliveryRouteData?.length ?
              <>
                <Typography
                  variant="h7"
                  fontWeight="bold"
                  display="inline">
                  No Upcoming Route Found
                </Typography>
                <CoreButton
                  sx={{
                    p: 0,
                    m: 0,
                    color: 'secondary.contrastText',
                    textDecoration: 'underline'
                  }}
                  fullWidth={false}
                  disableRipple
                  onClickHandler={() => navigate('/routes/new')}
                >
                  Add Route
                </CoreButton>
              </>
              : ''}
            options={deliveryRouteData || []}
          />
        </Grid>
        <Grid item xs={12}>
          <Alert severity="info">
            All selected customers will be updated with new route and delivery method as Home Delivery
          </Alert>
        </Grid></>}
    </Grid>}
    type="Update"
    actionHandler={async () => {
      setShowLoading(true);
      bulkOperation({ operation: APIS.CUSTOMER_BULK_OPERATIONS.CHANGE_ROUTE, ids: [...selectedItems], update: { routeId: newRoute?.id } });
      setSelectedItems([]);
      setSelectAllChecked(false);
      setShowLoading(false);
      setShowChangeRouteConfirmationModal(false);
      setNewRoute(null);
      setNewRouteInputValue('');
    }}
    actionHandlerDisabled={!newRoute}
  />;

  const changeTiffinConfirmationModal = <ConfirmationModal
    open={showChangeTiffinConfirmationModal}
    handleClose={() => {
      setShowChangeTiffinConfirmationModal(false)
    }}
    title={<Grid container spacing={2}>
      {tiffinDataLoading && <TuiSpinner />}
      {!tiffinDataLoading && <><Grid item xs={12}>
        <Typography variant='h6'>Select New Meal Plan</Typography>
      </Grid>
        <Grid item xs={12}>
          <TuiAutoComplete
            id="newTiffin"
            name="newTiffin"
            loading={tiffinDataLoading}
            freeSolo
            onChange={(event, item) => {
              setNewTiffin(item);
            }}
            inputValue={newTiffinInputValue}
            onInputChange={(event, newInputValue) => {
              if (newInputValue) {
                setNewTiffinInputValue(newInputValue);
              } else {
                setNewTiffinInputValue('');
              }
            }}
            value={newTiffin}
            label="New Meal Plan"
            labelKey='name'
            noOptionsText="No Item Found"
            options={tiffinData?.data?.items || []}
          />
        </Grid>
        <Grid item xs={12}>
          <Alert severity="info">
            It will only update name and items not price.
          </Alert>
        </Grid></>}
    </Grid>}
    type="Update"
    actionHandler={async () => {
      setShowLoading(true);
      bulkOperation({
        operation: APIS.CUSTOMER_BULK_OPERATIONS.CHANGE_MEAL_PLAN, ids: [...selectedItems], update: {
          tiffinId: newTiffin?.id,
        }
      });
      setSelectedItems([]);
      setSelectAllChecked(false);
      setShowLoading(false);
      setShowChangeTiffinConfirmationModal(false);
      setNewTiffin(null);
      setNewTiffinInputValue('');
    }}
    actionHandlerDisabled={!newTiffin}
  />;

  const selectedItemsMenu = (
    <Grid container sx={{ mt: 1 }} justifyContent="flex-end" direction="row" spacing={1}>
      <Grid item>
        <CoreButton
          variant="contained"
          onClickHandler={() => setShowAddHoldConfirmationModal(true)}
        >
          Add Hold
        </CoreButton>
      </Grid>
      <Grid item>
        <CoreButton
          variant="contained"
          onClickHandler={() => setShowChangeTiffinConfirmationModal(true)}
        >
          Change Meal Plan
        </CoreButton>
      </Grid>
      <Grid item>
        <CoreButton
          variant="contained"
          onClickHandler={() => setShowChangeRouteConfirmationModal(true)}
        >
          Change Route
        </CoreButton>
      </Grid>
      <Grid item>
        <CoreButton
          variant="contained"
          onClickHandler={() => setShowCancelConfirmationModal(true)}
        >
          Cancel Subscription
        </CoreButton>
      </Grid>
      <Grid item>
        <CoreButton
          variant="contained"
          onClickHandler={() => setShowDeleteConfirmationModal(true)}
        >
          Delete
        </CoreButton>
      </Grid>
      <Grid item>
        <Divider orientation="vertical" />
      </Grid>
      <Grid item>
        <IconButton
          disableRipple
          sx={{
            whiteSpace: 'nowrap',
            color: '#555555',
            '&:hover': {
              color: '#000000',
            },
          }}
          onClick={() => {
            setSelectedItems([]);
            setSelectAllChecked(false);
          }}
        >
          <CloseIcon />
        </IconButton>
      </Grid>
    </Grid>
  );

  const menuItems = (
    customerStatsData?.activeCount !== undefined && <Grid container spacing={1} sx={{ mt: 0.5 }}>
      <Grid item>
        <Chip
          label={`Expired: ${customerStatsData?.expiredCount}`}
          onClick={() => {
            let newStatus = '';
            if (filterObject?.status.includes(TIFFIN.STATUS.EXPIRED)) {
              newStatus = filterObject?.status.replace(new RegExp(`(,\\s*)?${TIFFIN.STATUS.EXPIRED}`), '').trim();
            } else {
              newStatus = filterObject?.status ? `${filterObject?.status},${TIFFIN.STATUS.EXPIRED}` : TIFFIN.STATUS.EXPIRED;
            }
            setFilterObject({ ...filterObject, status: newStatus });
            setPage(1);
            sessionStorage.setItem(STORAGE_KEYS.CUSTOMER_FILTER, JSON.stringify({ ...filterObject, status: newStatus }));
          }}
          variant='outlined'
          sx={{
            "& .MuiChip-label": {
              fontWeight: "bold"
            },
            color: filterObject?.status.includes(TIFFIN.STATUS.EXPIRED) ? 'white' : '#E31A1C',
            borderColor: '#E31A1C',
            backgroundColor: filterObject?.status.includes(TIFFIN.STATUS.EXPIRED) ? '#E31A1C' : 'transparent',
            "&:hover": {
              backgroundColor: filterObject?.status.includes(TIFFIN.STATUS.EXPIRED) ? '#E31A1C !important' : null,
              color: filterObject?.status.includes(TIFFIN.STATUS.EXPIRED) ? 'white' : "#E31A1C",
            }
          }}
        />
      </Grid>
      <Grid item>
        <Chip
          onClick={() => {
            let newStatus = '';
            if (filterObject?.status.includes(TIFFIN.STATUS.EXPIRING_SOON)) {
              newStatus = filterObject?.status.replace(new RegExp(`(,\\s*)?${TIFFIN.STATUS.EXPIRING_SOON}`), '').trim();
            } else {
              newStatus = filterObject?.status ? `${filterObject?.status},${TIFFIN.STATUS.EXPIRING_SOON}` : TIFFIN.STATUS.EXPIRING_SOON;

            }
            setFilterObject({ ...filterObject, status: newStatus });
            setPage(1);
            sessionStorage.setItem(STORAGE_KEYS.CUSTOMER_FILTER, JSON.stringify({ ...filterObject, status: newStatus }));
          }}
          variant='outlined'
          sx={{
            "& .MuiChip-label": {
              fontWeight: "bold"
            },
            color: filterObject?.status.includes(TIFFIN.STATUS.EXPIRING_SOON) ? 'white' : '#EE8D22',
            borderColor: '#EE8D22',
            backgroundColor: filterObject?.status.includes(TIFFIN.STATUS.EXPIRING_SOON) ? '#EE8D22' : 'transparent',
            "&:hover": {
              backgroundColor: filterObject?.status.includes(TIFFIN.STATUS.EXPIRING_SOON) ? '#EE8D22 !important' : null,
              color: filterObject?.status.includes(TIFFIN.STATUS.EXPIRING_SOON) ? 'white' : "#EE8D22",
            }
          }}
          label={`Expiring: ${customerStatsData?.expiringSoonCount}`}
        />
      </Grid>
      <Grid item>
        <Chip
          onClick={() => {
            let newStatus = '';
            if (filterObject?.status.includes(TIFFIN.STATUS.HOLD)) {
              newStatus = filterObject?.status.replace(new RegExp(`(,\\s*)?${TIFFIN.STATUS.HOLD}`), '').trim();
            } else {
              newStatus = filterObject?.status ? `${filterObject?.status},${TIFFIN.STATUS.HOLD}` : TIFFIN.STATUS.HOLD;
            }
            setFilterObject({ ...filterObject, status: newStatus });
            setPage(1);
            sessionStorage.setItem(STORAGE_KEYS.CUSTOMER_FILTER, JSON.stringify({ ...filterObject, status: newStatus }));
          }}
          variant='outlined'
          sx={{
            "& .MuiChip-label": {
              fontWeight: "bold"
            },
            color: filterObject?.status.includes(TIFFIN.STATUS.HOLD) ? 'white' : '#008B8B',
            borderColor: '#008B8B',
            backgroundColor: filterObject?.status.includes(TIFFIN.STATUS.HOLD) ? '#008B8B' : 'transparent',
            "&:hover": {
              backgroundColor: filterObject?.status.includes(TIFFIN.STATUS.HOLD) ? '#008B8B !important' : null,
              color: filterObject?.status.includes(TIFFIN.STATUS.HOLD) ? 'white' : "#008B8B",
            }
          }}
          label={`Hold: ${customerStatsData?.holdCount}`}
        />
      </Grid>
      <Grid item>
        <Chip
          label={`Active: ${customerStatsData?.activeCount}`}
          onClick={() => {
            let newStatus = '';
            if (filterObject?.status.includes(TIFFIN.STATUS.ACTIVE)) {
              newStatus = filterObject?.status.replace(new RegExp(`(,\\s*)?${TIFFIN.STATUS.ACTIVE}`), '').trim();
            } else {
              newStatus = filterObject?.status ? `${filterObject?.status},${TIFFIN.STATUS.ACTIVE}` : TIFFIN.STATUS.ACTIVE;
            }
            setFilterObject({ ...filterObject, status: newStatus });
            setPage(1);
            sessionStorage.setItem(STORAGE_KEYS.CUSTOMER_FILTER, JSON.stringify({ ...filterObject, status: newStatus }));
          }}
          variant='outlined'
          sx={{
            "& .MuiChip-label": {
              fontWeight: "bold"
            },
            color: filterObject?.status.includes(TIFFIN.STATUS.ACTIVE) ? 'white' : '#238443',
            borderColor: '#238443',
            backgroundColor: filterObject?.status.includes(TIFFIN.STATUS.ACTIVE) ? '#238443' : 'transparent',
            "&:hover": {
              backgroundColor: filterObject?.status.includes(TIFFIN.STATUS.ACTIVE) ? '#238443 !important' : null,
              color: filterObject?.status.includes(TIFFIN.STATUS.ACTIVE) ? 'white' : "#238443",
            }
          }}
        />
      </Grid>
      <Grid item>
        <Chip
          label={`Not Started: ${customerStatsData?.notStartedCount}`}
          onClick={() => {
            let newStatus = '';
            if (filterObject?.status.includes(TIFFIN.STATUS.NOT_STARTED)) {
              newStatus = filterObject?.status.replace(new RegExp(`(,\\s*)?${TIFFIN.STATUS.NOT_STARTED}`), '').trim();
            } else {
              newStatus = filterObject?.status ? `${filterObject?.status},${TIFFIN.STATUS.NOT_STARTED}` : TIFFIN.STATUS.NOT_STARTED;
            }
            setFilterObject({ ...filterObject, status: newStatus });
            setPage(1);
            sessionStorage.setItem(STORAGE_KEYS.CUSTOMER_FILTER, JSON.stringify({ ...filterObject, status: newStatus }));
          }}
          variant='outlined'
          sx={{
            "& .MuiChip-label": {
              fontWeight: "bold"
            },
            color: filterObject?.status.includes(TIFFIN.STATUS.NOT_STARTED) ? 'white' : '#2196f3',
            borderColor: '#2196f3',
            backgroundColor: filterObject?.status.includes(TIFFIN.STATUS.NOT_STARTED) ? '#2196f3' : 'transparent',
            "&:hover": {
              backgroundColor: filterObject?.status.includes(TIFFIN.STATUS.NOT_STARTED) ? '#2196f3 !important' : null,
              color: filterObject?.status.includes(TIFFIN.STATUS.NOT_STARTED) ? 'white' : "#2196f3",
            }
          }}
        />
      </Grid>
      <Grid item>
        <Chip
          label={`Cancelled: ${customerStatsData?.cancelledCount}`}
          onClick={() => {
            let newStatus = '';
            if (filterObject?.status.includes(TIFFIN.STATUS.CANCEL)) {
              newStatus = filterObject?.status.replace(new RegExp(`(,\\s*)?${TIFFIN.STATUS.CANCEL}`), '').trim();
            } else {
              newStatus = filterObject?.status ? `${filterObject?.status},${TIFFIN.STATUS.CANCEL}` : TIFFIN.STATUS.CANCEL;
            }
            setFilterObject({ ...filterObject, status: newStatus });
            setPage(1);
            sessionStorage.setItem(STORAGE_KEYS.CUSTOMER_FILTER, JSON.stringify({ ...filterObject, status: newStatus }));
          }}
          variant='outlined'
          sx={{
            "& .MuiChip-label": {
              fontWeight: "bold"
            },
            color: filterObject?.status.includes(TIFFIN.STATUS.CANCEL) ? 'white' : '#818181',
            borderColor: '#818181',
            backgroundColor: filterObject?.status.includes(TIFFIN.STATUS.CANCEL) ? '#818181' : 'transparent',
            "&:hover": {
              backgroundColor: filterObject?.status.includes(TIFFIN.STATUS.CANCEL) ? '#818181 !important' : null,
              color: filterObject?.status.includes(TIFFIN.STATUS.CANCEL) ? 'white' : "#818181",
            }
          }}
        />
      </Grid>
      <Grid item>
        <Divider orientation="vertical" />
      </Grid>
    </Grid>
  );

  const handleCheckboxChange = (header) => {
    setSelectedExportHeaders((prevSelectedHeaders) =>
      prevSelectedHeaders.includes(header)
        ? prevSelectedHeaders.filter((h) => h !== header)
        : [...prevSelectedHeaders, header]
    );
  };

  const downloadModal = (
    <ConfirmationModal
      open={showDownloadModal}
      handleClose={() => setShowDownloadModal(false)}
      title={
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h6">Select fields to download in excel sheet</Typography>
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              <Grid container spacing={2}>
                {exportHeaders.map((header, index) => (
                  <Grid item xs={4} key={index}>
                    <FormControlLabel
                      label={header}
                      control={
                        <Checkbox
                          checked={selectedExportHeaders.includes(header)}
                          onChange={() => handleCheckboxChange(header)}
                          sx={{
                            color: 'secondary.contrastText',
                            '&.Mui-checked': {
                              color: 'secondary.contrastText',
                            },
                          }}
                        />
                      }
                    />
                  </Grid>
                ))}
              </Grid>
            </FormGroup>
          </Grid>
        </Grid>
      }
      type="Download"
      actionHandler={async () => {
        setShowDownloadModal(false);

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Customers');

        // Add headers
        worksheet.columns = selectedExportHeaders.map((header) => ({ header, key: header }));

        // Add rows
        rows.forEach((row) => {
          const rowData = selectedExportHeaders.map((header) => {
            if (header === 'Name') {
              return `${row?.customer?.firstName} ${row?.customer?.lastName}`;
            } else if (header === 'Email') {
              return row?.customer?.email;
            } else if (header === 'Phone') {
              return `${row?.customer?.phoneNumber?.countryCode} ${getFormattedPhoneNumber(row?.customer?.phoneNumber?.number)}`;
            } else if (header === 'Address') {
              return row?.customer?.address?.description;
            } else if (header === 'Unit') {
              return row?.customer?.unit;
            } else if (header === 'Route Name') {
              return row?.route?.name;
            } else if (header === 'Meal Plan Name') {
              return row?.tiffin?.name;
            } else if (header === 'Meal Plan Frequency') {
              return row?.tiffin?.plan?.type;
            } else if (header === 'Price') {
              return row?.tiffin?.plan?.price;
            } else if (header === 'Status') {
              return row?.status;
            } else if (header === 'Start Date') {
              return getFormattedDateFromUnixTimestamp(row?.startDate);
            } else if (header === 'End Date') {
              return getFormattedDateFromUnixTimestamp(row?.endDate);
            } else if (header === 'Delivery Days') {
              return DAYS.map(({ shortName, value }) => `${shortName}: ${row.deliveryDays[value]}`).join(', ');
            } else if (header === 'Comment') {
              return row?.comment;
            } else if (header === 'Delivery Instructions') {
              return row?.deliveryInstructions;
            } else if (header === 'Created Date') {
              return getFormattedDateFromUnixTimestamp(row?.createdAt);
            }
          });
          worksheet.addRow(rowData);
        });

        // Generate Excel file and save it
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, `Customers_${getFormattedDateTimeFromUnixTimestamp(Date.now(), true, true, 'YYYY/MM/DD')}.xlsx`);
      }}
    />
  );

  const pageCount = Math.ceil(data?.data?.totalCount / pageSize);

  return (
    <>
      <Grid container spacing={1} sx={{ height: data?.data?.items?.length <= 0 ? '100%' : null }}>
        <Grid item xs={12} md={12} lg={12}>
          <TuiAppBar
            buttonDisabled={!roles?.customers?.create}
            buttonClickHandler={() => {
              if (customerCreateLimitLeft <= 0) {
                errorSnackBar({
                  key: 'customerCreateLimit',
                  message: 'You have reached the maximum number of customers allowed by your plan. Please upgrade your plan to add more customers.'
                });
                return;
              }
              navigate('new');
            }}
            exportData={{ exportHandler: () => setShowDownloadModal(true) }}
            setSearchText={setSearchText}
            searchText={searchText?.toString()}
            searchHelperText='Name/Address/Tiffin'
            selectedItems={selectedItems}
            menuItems={menuItems}
            selectedItemsMenu={selectedItemsMenu}
            filter={{ filterModal, showFilterModal, setShowFilterModal, filterCount }}
            title={<Box display="flex" alignItems="center">
              <Typography variant="h6" fontWeight="bold" sx={{ pl: 1 }}>
                Customers
              </Typography>
              <IconButton
                onClick={() => {
                  refetch();
                  refetchCustomerStats();
                }}
                aria-label="refresh"
                size="medium">
                <RefreshIcon fontSize="inherit" style={{ color: '#FF4444' }} />
              </IconButton>
            </Box>}
          />
        </Grid>
        <Grid item xs={12}>
          {isFetching || showLoading || customerStatsLoading || bulkLoading ? <TuiSpinner /> :
            !data?.data?.items?.length && !isFetching ?
              <DataNotFound title="customers" /> :
              <Grid container item xs={12} sx={{ pt: 2 }} spacing={1}>
                {selectedItems?.length > 0 && pageCount > 1 && <Grid item xs={12} container sx={{ backgroundColor: 'lightyellow', ml: 1, borderRadius: 2 }}>
                  <Grid item xs='auto'>
                    <Typography variant="body1" sx={{ color: 'grey' }}>
                      {
                        selectedItems?.length === data?.data?.totalCount ?
                          `${data?.data?.totalCount} customers have been selected.`
                          :
                          `${selectedItems?.length} customers on this page have been selected.`
                      }
                    </Typography>
                  </Grid>
                  <Grid item xs="auto" sx={{ ml: 1 }}>
                    {selectedItems?.length !== data?.data?.totalCount && <CoreButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      disableRipple
                      sx={{
                        color: 'secondary.contrastText',
                        textDecoration: 'underline',
                      }}
                      isLoading={showLoading}
                      onClickHandler={() => {
                        // fetch subscriber recursively and push ids to selectedItems
                        selectAllOtherCustomers()
                      }}
                    >
                      {`Select all ${data?.data?.totalCount} customers`}
                    </CoreButton>}
                  </Grid>
                </Grid>}
                <Grid item xs={12} sx={{ pb: 15 }}>
                  {data?.data?.items?.length > 0 && <CustomerListComponent
                    refetchCustomerStats={refetchCustomerStats}
                    isLoading={isFetching}
                    selectedItems={selectedItems}
                    setSelectedItems={setSelectedItems}
                    selectAllHandler={selectAllHandler}
                    selectAllChecked={selectAllChecked}
                    rows={rows}
                    setRows={setRows}
                    page={page}
                    setPage={setPage}
                    filterObject={filterObject}
                    customerCreateLimitLeft={customerCreateLimitLeft}
                  />}
                </Grid>
              </Grid>}
        </Grid>
      </Grid>
      <Footer>
        <Grid item xs={12} container justifyContent='flex-end' spacing={1}>
          <Grid item xs='auto'>
            {data?.data?.routeMissingWarningCount > 0 &&
              <Alert
                severity='warning'
                action={
                  <Button
                    color="inherit"
                    size="small"
                    onClick={() => {
                      if (filterObject?.shipping !== DELIVERY_METHOD.HOME_DELIVERY &&
                        filterObject?.route !== 'null') {
                        setFilterObject({ ...filterObject, route: 'null', shipping: DELIVERY_METHOD.HOME_DELIVERY });
                        sessionStorage.setItem(STORAGE_KEYS.CUSTOMER_FILTER, JSON.stringify({ ...filterObject, route: 'null' }));
                        setFilterCount(filterCount + 2);
                      } else {
                        setFilterObject({ ...filterObject, route: '', shipping: '' });
                        sessionStorage.setItem(STORAGE_KEYS.CUSTOMER_FILTER, JSON.stringify({ ...filterObject, route: '' }));
                        setFilterCount(filterCount - 2);
                      }
                      setPage(1);
                    }}
                  >
                    {
                      filterObject?.shipping !== DELIVERY_METHOD.HOME_DELIVERY &&
                        filterObject?.route !== 'null' ? 'APPLY FILTER' : 'CLEAR FILTER'
                    }
                  </Button>
                }>
                {data?.data?.routeMissingWarningCount} customers are missing route.
              </Alert>
            }
          </Grid>
          <Grid item xs='auto'>
            {customerCreateLimitLeft <= 5 &&
              <Alert
                severity={customerCreateLimitLeft <= 0 ? 'error' : 'warning'}>
                {customerCreateLimitLeft <= 0
                  ? 'You have reached the maximum number of customers allowed by your plan. Please upgrade your plan to add more customers.'
                  : `You can add only ${customerCreateLimitLeft} more ${customerCreateLimitLeft === 1 ? 'customer' : 'customers'} as per your plan. Consider upgrading your plan.`}
              </Alert>
            }
          </Grid>
          {selectedItems?.length <= 0 && <Grid item xs='auto'><BasePagination
            data={data}
            pageSize={pageSize}
            page={page - 1}
            pageSizeChangeHandler={(event) => {
              setPageSize(parseInt(event.target.value));
              setPage(1);
            }}
            pageChangeHandler={(event, val) => {
              setPage(val + 1);
            }}
            localStorageKey={STORAGE_KEYS.CUSTOMER_PAGINATION}
          /></Grid>}
        </Grid>
      </Footer >
      {showDeleteConfirmationModal && deleteConfirmationModal}
      {showCancelConfirmationModal && cancelConfirmationModal}
      {showAddHoldConfirmationModal &&
        <CustomerHoldForm
          action="add"
          open={showAddHoldConfirmationModal}
          handleClose={() => setShowAddHoldConfirmationModal(false)}
          parentHandleSubmit={(data) => {
            setShowLoading(true);
            const isOverlapping = rows.some(row => {
              const newStart = moment(data.startDate);
              const newEnd = moment(data.endDate);

              if (selectedItems.includes(row.id)) {
                const existingStart = moment(row.startDate);
                const existingEnd = moment(row.endDate);

                return newStart.isBetween(existingStart, existingEnd, null, '[]') ||
                  newEnd.isBetween(existingStart, existingEnd, null, '[]') ||
                  existingStart.isBetween(newStart, newEnd, null, '[]') ||
                  existingEnd.isBetween(newStart, newEnd, null, '[]');
              }
            });

            if (!isOverlapping) {
              errorSnackBar({ message: 'Hold dates cannot overlap' });
              setShowLoading(false);
              return;
            }
            setShowAddHoldConfirmationModal(false);
            bulkOperation({ operation: APIS.CUSTOMER_BULK_OPERATIONS.ADD_HOLD, ids: [...selectedItems], update: { holdDates: [data] } });
            setSelectedItems([]);
            setSelectAllChecked(false);
            setShowLoading(false);
          }}
          isLoading={bulkLoading}
        />}
      {showChangeRouteConfirmationModal && changeRouteConfirmationModal}
      {showDownloadModal && downloadModal}
      {showChangeTiffinConfirmationModal && changeTiffinConfirmationModal}
    </>
  );
}
